import React, { useEffect, useState } from "react";
import "./myOrder.css";
import { makeStyles, Paper } from "@material-ui/core";
import Footer from "../Footer/Footer";
import { useHistory } from "react-router-dom";
import { auth, firebase, functions } from "../../firebaseConfig";
const db = firebase.firestore();

const useStyles = makeStyles((theme) => ({
  root: {
    shadows: ["none"],
  },
}));

function MyOrder() {
  const history = useHistory();
  const classes = useStyles();
  const [myOrder, setMyOrder] = useState([]);

  useEffect(async () => {
    const data = firebase.auth().currentUser;
    let orderData = await db.collection("orders").where("email", "==", data.email).get()
      .then((data) => {
        let returnData = data.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))
        return returnData
      });
    setMyOrder(orderData);

  }, [])

  return (
    <>
      <div className="checkout">
        <div className="ck_head">
          <h1 className={'myOrder'}>MY ORDER</h1>
        </div>
        <div className="ck_details" style={{ marginTop: '1rem' }}>
          <Paper square={true} className={classes.root}>
            <div style={{ overflowX: 'auto' }}>
              {myOrder.length>0?
              <table>
                <tr className="tb_hd">
                  <th className={'minWidth200 tableHead22'}>ITEM</th>
                  <th className={'tableHead22'} style={{ textAlign: 'center' }}>HANGER </th>
                  <th style={{ textAlign: 'center' }} className="tableHead22">YARDAGE</th>
                </tr>
                {myOrder.map((items, index) => {
                  let order = items.id;
                  return (
                    items.items.map((data) => {
                      return (
                        <tr style={{border:order==items.id?'1px solid red':'5px solid black'}}>
                          <td className="td_sum minWIdth200">
                            <div className="mainProductView">
                              <img
                                src={data.img.asset.url}
                                className="tb_img"
                                alt="imggg"
                                style={{ cursor: 'pointer' }}
                                onClick={() => history.push("/product/" + data.id)}
                              />
                              <div className="nameAndDelete" style={{justifyContent:'space-between'}}>
                                <p className="ml-4 productTextCart itemNameOrder">{data.fabricID}</p>
                                <div className={'mainOrderData'}>
                                  <div className={'orderTime'}>{items.date}-{items.time}</div>
                                  <div className={'orderStatus'}>{items.status.toUpperCase()}</div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="ck_hang  " style={{ border: "none", width: '100%' }}>
                              <p className="mt-2">{data.hangerQTY}</p>
                            </div>
                          </td>
                          <td>
                            <div className="ck_hang  " style={{ border: "none", width: '100%' }}>
                              <p className="mt-2">{data.yardageQTY}</p>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                  )
                })}
              </table>
              :
                <div className={'noOrderText'}>No Order Found</div>
                }
            </div>
          </Paper>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default MyOrder;
