import React, { useRef, useState } from "react";
import { Form, Alert, Modal, Button } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import "./login.css";
import { BiChevronLeft } from "react-icons/bi";
import Footer from "../Footer/Footer";
import { Paper } from "@material-ui/core";
import UCO from "../../assets/UCOlogo.png";
import { auth, firebase, functions } from "../../firebaseConfig";

import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";

const db = firebase.firestore();

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);

      let userData = await db.collection("users").where("email", "==", emailRef.current.value?emailRef.current.value.toLowerCase():'').get()
        .then((data) => {
          let returnData = data.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }))
          return returnData
        });
      if (userData.length > 0 && userData[0].activated) {
        await login(emailRef.current.value, passwordRef.current.value);
        history.push("/");
      }
      else {
        if (userData.length > 0 && userData[0].onBoarded) {
          setError("Your account is deactivated");
        }
        else {
          setError("Failed to log in please check your email and password");
        }
      }
    } catch (e) {
      setError("Failed to log in please check your email and password");
    }
    setLoading(false);
  }

  const forgotPassword = async () => {
    await checkUser();
  }

  const checkUser = async () => {
    if (emailRef.current.value) {
      let email = emailRef.current.value ? emailRef.current.value.toLowerCase() : '';
      let userData = await db.collection("users").where("email", "==", email).get()
        .then((data) => {
          let returnData = data.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }))
          return returnData;
        });
      if (userData.length > 0) {
        var user = firebase.auth();
        auth.sendPasswordResetEmail(email).then(() => {
          Swal.fire({
            text: "Please check your email and reset your password"
          });
        })
      }
      else {
        Swal.fire({
          text: "Please enter registered email address",
        });
      }
    }
    else {
      Swal.fire({
        text: "Please enter registered email address",
      });
    }
  }

  return (
    <>
      <div className="login">
        <div className="back-to-homepage">
          <BiChevronLeft />
          <Link to="/">
            <div>BACK TO HOME PAGE</div>
          </Link>
        </div>

        <div className="login_form">
          <Paper elevation={1} variant="outlined">
            <Form onSubmit={handleSubmit}>
              <div className="form_text mt-3">
                <img src={UCO} alt="raymond" className="ray_logo" />
              </div>
              <div className="main_form mt-4">
                <input
                  className="UserName"
                  type="text"
                  name="username"
                  autoComplete="off"
                  placeholder="username or email"
                  ref={emailRef}
                />
                <input
                  className="passWord mt-3"
                  type="password"
                  name="password"
                  autoComplete="off"
                  placeholder="password"
                  ref={passwordRef}
                />
                <button
                  disabled={loading}
                  className="login_button"
                  type="submit"
                >
                  Login
                </button>
              </div>
              <div className="login_bottom">
                <p className={'forgot_pass'} onClick={forgotPassword}>
                  Forgot Password ?
                </p>
              </div>
              <div className="register_bottom mt-4">
                <p className="account_check">
                  Do You Have an Account?
                  <span style={{ paddingLeft: 5 }}>
                    <Link to="/register" className="register_user">
                      Register
                    </Link>
                  </span>
                </p>
              </div>
              {error && <Alert variant="danger">{error}</Alert>}
            </Form>
          </Paper>
        </div>
        <Footer />
      </div>
    </>
  );
}
