import React, { useState, useEffect } from "react";
import arrow from "../../assets/Icons and Buttons/arrow-up.svg";
import "./faq.css";
import { Form, Alert } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Footer from "../Footer/Footer";
import "aos/dist/aos.css";
import Aos from "aos";
import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "../../client";
import { CircularProgress } from "@material-ui/core";
import { motion } from "framer-motion";
import { Fade } from "react-awesome-reveal";
import { firebase, functions } from "../../firebaseConfig";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import axios from 'axios';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    color: "black",
  },
  head: {
    backgroundColor: "transparent",
  },
  content: {
    color: "black",
  },
  expand_icon: {
    color: "black",
  },
}));

const Faq = () => {
  Aos.init();
  const classes = useStyles();
  const [postData, setPostData] = useState(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="contact"]{
            image{
                asset->{
                _id,url  
                } 
            },
            title,
            h2,
            email,
            h3,
            phone,
            description,
            section1{
                q1,a1,q2,a2,q3,a3,q4,a4,q5,a5
            },
            address{
              l1,l2,l3,l4,l5,l6
            },
            addressImage{
              asset->{
                url  
              } 
          },
          }
        `
      )
      .then((data) => { setPostData(data[0]) })
      .catch(console.error);
  }, []);
  if (!postData) {
    return (
      <div className="load_div">
        <CircularProgress />
      </div>
    );
  }

  const emailSent = () => {
    setName("");
    setEmail("");
    setSubject("");
    setMessage("");

    Swal.fire({
      title: "We have Recieved your request for contact.",
      text:
        "We'll connect with you as soon as possible",
    });
  }

  const sendEmailToUser = async () => {
    var data = JSON.stringify({
      name: name,
      email: email,
      header: "Contact Request",
      subject: "Contact Request",
      line1: "Thank you for getting touch with us, someone from our marketing team will be in touch with you soon.",
      line2: "Regarding to " + subject + " (" + message + ")"
    });

    var config = {
      method: 'post',
      url: 'https://us-central1-rayu-f9820.cloudfunctions.net/sendEmail',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log("EEEE",error);
      });
  }

  const sendEmailToAdmin = async () => {

    var data = JSON.stringify({
      name: "Admin",
      email: "denim@raymond.in",
      header: "Contact Request",
      subject: "Contact Request",
      line1: "You have received one new request from " + name + " (" + email + ") to contact regarding to " + subject + " (" + message + ")",
      line2: ""
    });

    var config = {
      method: 'post',
      url: 'https://us-central1-rayu-f9820.cloudfunctions.net/sendEmail',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  const sendButtonClick = async (e) => {
    e.preventDefault();

    sendEmailToUser();
    sendEmailToAdmin();

    setTimeout(() => {
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
    }, 1000);
  }

  return (
    <>
      <div className="faq">
        <Fade delay={500}>
          <div className="faq_mainImg">
            <img src={urlFor(postData.image).url()} alt="main_bgImg" />
          </div>
        </Fade>

        <motion.div
          className="text-header"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.7 }}
        >
          {postData.title}
        </motion.div>
        <div className="sub-text">{postData.h2}</div>
        <div className="emailPrimary">{postData.email}</div>
        <div className="start-collab">{postData.h3}</div>
        <div className="email-2">{postData.phone}</div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8 }}
          className="sub-header"
        >
          Need Help? <br /> Contact Us.
        </motion.div>

        <div className="faq_parent mainInFAQ">
          <div className="flex flex-col fp_left">
            <div className="faq_woven">{postData.description}</div>
            <div className="faq_boxes">
              <div className="l_chat">24/7 Support</div>
              <div className="c_ray">{postData.email}</div>
            </div>
          </div>

          <Form onSubmit={sendButtonClick}>
            <div
              // name="contact_form"
              // netlify
              className="form-1"
              data-aos="fade-left"
              data-aos-mirror="false"
              data-aos-once="true"
              data-aos-offset="200"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
            >
              <div className="form_gr mb-4 mt-3 border-b border-gray-900">
                <input type="text" id="subdomaintwo" value="Name" disabled />
                <input
                  className="Phone pl-2"
                  type="name"
                  name="name"
                  placeholder="e.g: John Show"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form_gr mb-4 mt-3 border-b border-gray-900">
                <input type="text" id="subdomaintwo" value="Email" disabled />
                <input
                  className="Phone pl-2"
                  type="email"
                  name="email"
                  required
                  value={email}
                  placeholder="e.g: John@emailid.com"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form_gr mb-4 mt-3 border-b border-gray-900">
                <input type="text" id="subdomaintwo" value="Subject" disabled />
                <input
                  className="Phone pl-2"
                  type="name"
                  name="name"
                  required
                  value={subject}
                  placeholder="What is this about?"
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>

              <div id="message" className="inpContact">
                Message
              </div>
              <textarea
                className="inpContact"
                maxLength="500"
                required
                value={message}
                placeholder="Write your message here …"
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <button type="submit" className="sendBtn">Send</button>
            </div>
          </Form>
        </div>

        <div
          className="FAQ"
          data-aos="fade-up-right"
          data-aos-mirror="false"
          data-aos-once="true"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          Frequently Asked <br /> Questions
        </div>
        <div
          className={"newFullWidth"}
          data-aos="fade-up-right"
          data-aos-mirror="false"
          data-aos-once="true"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <Accordion elevation={0} className={classes.head}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expand_icon} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                {postData.section1.q1}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.content}>
                {postData.section1.a1}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} className={classes.head}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                {postData.section1.q2}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.content}>
                {postData.section1.a2}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} className={classes.head}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                {postData.section1.q3}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.content}>
                {postData.section1.a3}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} className={classes.head}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                {postData.section1.q4}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.content}>
                {postData.section1.a4}
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0} className={classes.head}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                {postData.section1.q5}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.content}>
                {postData.section1.a5}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
        <div
          className="bt_imgLast"
          data-aos="zoom-in"
          data-aos-mirror="false"
          data-aos-once="true"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <div className="bottom-image">
            <img className={'addressImage'} src={postData.addressImage.asset.url}></img>
            <div className="bottom-text">
              {postData.address.l1}
              <br />
              {postData.address.l2} <br />
              {postData.address.l3}
              <br />
              {postData.address.l4}
              <br /> {postData.address.l5}
              <br />
              {postData.address.l6}
            </div>
            {/* <div className="bottom-text2">Stay Updated. Stay in Touch.</div> */}
            {/* <img src={arrow} className="arrow-up" /> */}
          </div>
        </div>

        <div className="faq_footer">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Faq;
