import firebase from "firebase";
import "firebase/storage";
import "firebase/functions";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyClhTtZluU3aswCrzGjQxDnPEZgaVktaFM",
  authDomain: "rayu-f9820.firebaseapp.com",
  databaseURL: "https://aggrio-8b3d3.firebaseio.com",
  projectId: "rayu-f9820",
  storageBucket: "rayu-f9820.appspot.com",
  messagingSenderId: "792051432432",
  appId: "1:792051432432:web:329dc7b2f9d9bef47bbb35",
  measurementId: "G-D3RWRF57K1",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.functions();
const storage = firebase.storage();
const functions = firebase.functions();
const auth = firebase.auth();

export { firebase, storage, functions, auth };
