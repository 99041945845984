import React from "react";
import "./cartStyle.css";
import { AiOutlineClose } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { useHistory } from "react-router-dom";

import { Background, ModalWrapper } from "./CartPopupStyles";
import { Paper, Button } from "@material-ui/core";
import { connect } from "react-redux";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import { removeFromCart } from "../../redux/Shopping/shopping-actions";
import EmptyCart from "./EmptyCart";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const CartPopup = ({ showCart, setShowCart, cart, removeFromCart }) => {
  const history = useHistory();
  const goCheck = () => {
    history.push("/checkout");
    setShowCart((prev) => !prev);
  };

  return (
    <>
      {showCart ? (
        <Background>
          <ModalWrapper>
            <Paper elevation={0} style={{backgroundColor:'transparent',height:'100vh',maxHeight:'100vh',paddingBottom:'6rem',marginRight:'-0.4rem'}}>
              <div className="parent_div">
                <div className="cartHead flex justify-between items-center">
                  <div>Your Shopping Bag</div>
                  <AiOutlineClose
                    className="cursor-pointer"
                    onClick={() => setShowCart((prev) => !prev)}
                  />
                </div>
                {cart.length === 0 ? <EmptyCart /> : null}
                {cart.map((items, index) => {
                  return (
                    <>
                      <div className="cartSummary border-b border-black">
                        <div className="cs_img mb-1">
                          <img src={urlFor(items.img).url()} alt="fashion" style={{cursor:'pointer'}} onClick={() => {history.push("/product/"+items.id);setShowCart((prev) => !prev)}} />
                        </div>
                        <div className="cs_desc">
                          {/* <div> */}
                            <h5>{items.fabricID}</h5>
                          {/* </div> */}
                          <Button
                            className="cursor-pointer"
                            onClick={() => removeFromCart(items.id)}
                          >
                            <BsTrash className="del_pro mt-1" />
                          </Button>
                          <div className="flex justify-between items-center mt-2">
                            <p className={'htextPop'}>Hanger:{items.hangerQTY}</p>
                            <p className="ytextPop" style={{marginRight:20}}>Yardage:{items.yardageQTY}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}

                {cart.length === 0 ? (
                  <div
                    className="cs_bts"
                    onClick={() => {
                      setShowCart((prev) => !prev);
                      history.push("/allCollections");
                    }}
                  >
                    <button className="bg-black cs_btn uppercase">
                      Go Shopping
                    </button>
                  </div>
                ) : (
                  <div className="cs_bts" onClick={goCheck}>
                    <button className="bg-black cs_btn uppercase">
                      Express Checkout
                    </button>
                  </div>
                )}
              </div>
            </Paper>
          </ModalWrapper>
        </Background>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.shop.cart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    removeFromCart: (id) => dispatch(removeFromCart(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartPopup);
