import React from "react";
import { Modal, Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ModalWrapper } from "../Popup/ModalStyles";
import MovingArrow from "../../assets/Icons and Buttons/buttonArrow.svg";
import "../Popup/modal.css";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    outline: "none",
    border: "1px solid red",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const RegisterPopUp = ({ open, setOpen, handleClose }) => {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <ModalWrapper>
          <div className="w-full flex justify-end">
            {/* <div className="popup_close flex justify-center items-center mr-2 cursor-pointer" onClick={handleClose}>
                            <AiFillCloseCircle className="clo_btn" />
                        </div> */}
          </div>
          <div className="modal_content flex flex-col  items-center">
            <div className="modal_header mt-3">
              <h6>Connect with us</h6>
            </div>
            <div className="modal_para mt-4">
              <h6>
                We announcea major update for OUR VIRTUAL SHOWCASE - with the
                launch of our new digital platform, where brands, retailers and
                press can manage their business at any time.
                <br />
                <br />
                Be among the first to discover how user-friendly it is and how
                it will help your digital buying experience through its multiple
                services dedicated specifically to you as a retailer.
              </h6>
            </div>
            <div className="modal_button mt-2" onClick={handleClose}>
              <Link to="/register">
                <button className=" button_content flex justify-end items-center">
                  <div className="mr-14 ">Register With Us</div>
                  <img style={{marginRight:'2px'}} className="mov" src={MovingArrow} alt="movingArrow" />
                </button>
              </Link>
            </div>
          </div>
        </ModalWrapper>
      </Modal>
    </div>
  );
};

export default RegisterPopUp;
