import React from 'react';
import { Link } from 'react-router-dom'

const DropDown = ({ isOpen, toggle }) => {
    return (
        <div id="dropD" className={isOpen ? "grid grid-rows-4 text-red text-center items-center bg-black" : 'hidden'} onClick={toggle}>
            <Link to='/allCollections' className="p-2">COLLECTIONS</Link>
            <Link to='/sustain' className="p-2">SUSTAINABILITY</Link>
            <Link to='/access' className="p-2">ABOUT US</Link>
            <Link to='/faq' className="p-2">CONTACT US</Link>
            <Link to='/register' className="p-2">REGISTER</Link>
            <Link to='/login' className="p-2">LOGIN</Link>
        </div>
    )
}

export default DropDown
