import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./greenProduct.css";
import AOS from "aos";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Footer/Footer";
import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "../../client";
import FeaturedProductComponent from "../AllCollections/FeaturedProductComponent";
import CollectionSpecific from "../AllCollections/CollectionSpecific";
import { CircularProgress } from "@material-ui/core";
import { motion } from "framer-motion";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const GreenLProduct = () => {
  const { slug } = useParams();
  const [postData, setPostData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}" && _type=="collectionPage"]{
            title,
            description,
            mainImage{
            asset->{
                _id, url
            }
            },
            subDescription,
            "product": products[]->{title,weight,fabricID},
            discover,
            section1{
                carouselimages[]{
                asset->{
                _id,url
                }
                },
                d1,
                d2,
                d3,
                description,
                h1,
                h2,
                h3,
                header,
                mi{
                asset->{
                _id,url
                }
                },
                s1,
                s2,
                s3,
                slug 
            },
                tags, 
                videofile{
                asset->{
                _id,url
                }
                },
                badgeHead,
                badges[]{
                asset->{
                _id,url
                }
                }
            
            }`
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  }, [slug]);
  if (!postData)
    return (
      <div className="load_div">
        <CircularProgress />
      </div>
    );

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: true,
  };

  const caro = postData.section1.carouselimages.map((data, i) => {
    return (
      <div className="carI1 ec_slide">
        <img src={urlFor(data).url()} alt="ca1" />
      </div>
    );
  });

  AOS.init();
  return (
    <div className="gl_product">
      {/* <div className="top_text">
        <div className="top_para">Collection Name</div>
      </div> */}

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.6 }}
        className="gl_mainImg"
        style={{
          backgroundImage: `url(${urlFor(postData.mainImage).url()})`,
        }}
      >
        <motion.div initial={{ y: '-800px' }} animate={{ y: 0 }} transition={{ delay: 1, type: "spring", stiffness: 50 }} className="intro_top">Introducing</motion.div>
        <motion.div initial={{ y: '-800px' }} animate={{ y: 0 }} transition={{ delay: 1, type: "spring", stiffness: 50 }} className="main_intro">{postData.title}</motion.div>
        <motion.div initial={{ y: '-800px' }} animate={{ y: 0 }} transition={{ delay: 1, type: "spring", stiffness: 50 }} className="intro_sub">{postData.description}</motion.div>
      </motion.div>

      <div className="red_section">
        <h5>{postData.tags[0]} </h5>
        <h5>{postData.tags[1]}</h5>
        <h5>{postData.tags[2]}</h5>
        <h5>{postData.tags[3]}</h5>
        <h5>{postData.tags[4]}</h5>
      </div>

      <div className="down_para">
        <h6>{postData.subDescription}</h6>
      </div>

      <div className={'minus55MT'}>
        <FeaturedProductComponent slug={slug} />
      </div>

      <div className="discover_section">
        <h5> Discover The Collection</h5>
      </div>
      <div className="discover_para">
        <h5>{postData.discover}</h5>
      </div>

      <video
        // autoPlay
        loop
        src={postData.videofile.asset.url}
        type="video/mp4"
        className="vd_bcg"
        controls
      />

      {/* ecover section */}
      <div className="ecover_section">
        <div
          className="ecover_left"
          style={{
            backgroundImage: `url(${urlFor(postData.section1.mi).url()})`,
            backgroundSize: "cover",
            objectFit: "contain",
          }}
        >
          <div className="overlay-text">
            <div className="head-1">{postData.section1.h1}</div>
            <div className="sub-1">{postData.section1.s1}</div>
            <div className="cont-1">{postData.section1.d1}</div>
            <div className="head-2">{postData.section1.h2}</div>
            <div className="sub-2">{postData.section1.s2}</div>
            <div className="cont-2">{postData.section1.d2}</div>
            <div className="head-3">{postData.section1.h3}</div>
            <div className="sub-3">{postData.section1.s3}</div>
            <div className="cont-3">{postData.section1.d3}</div>
          </div>
        </div>

        <div className="ecov_right flex flex-col">
          <div className="ecr_tt mt-4">
            <h5>{postData.section1.header}</h5>
          </div>
          <div className="ecr_para mt-4">
            <h6>{postData.section1.description}</h6>
          </div>
          {/* <div className="ecr_btn"> */}
            {/* <Link to="/register">
              <button class="button_content btn1 btn-primary1">
                Explore The Collection
                <img
                  className="moving_arrow icon hoz_move"
                  src={MovingArrow}
                  alt="movingArrow"
                />
              </button>
            </Link> */}
          {/* </div> */}
          <div className="ecr_carousal">
            {/* <Slider {...settings}>{caro}</Slider> */}
            <div className={'mainProImage2'}>{caro}</div>
          </div>
        </div>
      </div>

      <div className="discover_collection">
        {/* <div className="dc_text"> */}
          {/* <h5>DISCOVER THE COLLECTION</h5>{" "} */}
        {/* </div> */}
        <div className="dc_para">{postData.badgeHead}</div>
      </div>

      <div className="eco_div">
        <div className="eco_product1 ep">
          <img src={urlFor(postData.badges[0]).url()} alt="greenlogo" />
        </div>
        <div className="eco_product2 ep">
          <img src={urlFor(postData.badges[1]).url()} alt="greenlogo" />
        </div>
        <div className="eco_product3 ep">
          <img src={urlFor(postData.badges[2]).url()} alt="greenlogo" />
        </div>
        <div className="eco_product4 ep">
          <img src={urlFor(postData.badges[3]).url()} alt="greenlogo" />
        </div>
        <div className="eco_product5 ep">
          <img src={urlFor(postData.badges[4]).url()} alt="greenlogo" />
        </div>
      </div>

      {/* <div className="dropdown_section">
                dropdown
            </div> */}

      <div className="gl_coll">
        <CollectionSpecific collection={postData.title} />
      </div>

      <div className="gl_footer">
        <Footer />
      </div>
    </div>
  );
};

export default GreenLProduct;
