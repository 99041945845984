import React from 'react';
import './mobilePopUp.css';
import { Modal, Backdrop } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        outline: 'none',
    },
}));

const MobilePopUp = (openMobile) => {
    const classes = useStyles();
    return (
        <div>
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={openMobile}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <div className="modal_wrapper">
                        <div className="modal_content flex flex-col  items-center">
                            <div className="modal_header mt-3">
                                <h6>Please visit our desktop Site</h6>
                            </div>
                        </div>

                    </div>

                </Modal>

            </div>
        </div>
    )
}

export default MobilePopUp
