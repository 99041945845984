import React, { useEffect, useState } from "react";
import "./landingColl.css";
import arrow2 from "../../assets/arrow2.svg";
import MovingArrow from "../../assets/Icons and Buttons/buttonArrow.svg";
import { Link } from "react-router-dom";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import { CircularProgress } from "@material-ui/core";
import { motion } from "framer-motion";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const LandingColl = () => {
  const [allPostsData, setAllPosts] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="home"]{
        section4{
            s4Header,
            s4SubHeader,
            s4Underlined,
            s4Header2,
            s4SubHeader2,
            s4Content,
            s4SubHeader,
            s4img1{
            asset->{
            _id,url
            }
          },
            s4img2{
            asset->{
            _id,url
            }
          },
        },        
        section5{
          s5Header,
          s5Text,
          s5img1{
          asset->{
          _id,url
          }
         },
          s5img2{
          asset->{
          _id,url
          }
         }
        }
      }`
      )
      .then((data) => setAllPosts(data[0]))
      .catch(console.error);
  }, []);

  if (!allPostsData)
    return (
      <div className="load_div">
        <CircularProgress />
      </div>
    );
  return (
    <div className="landing_collection">
      <div
        className="lnc_topsection"
        data-aos="zoom-in"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <div className="ts_text">
          <p className="ts_p">{allPostsData.section4.s4Header}</p>
        </div>
        <div className="ts_para mt-3">{allPostsData.section4.s4SubHeader}</div>
        <div className="arrow2 mt-4">
          <img src={arrow2} alt="arrow" className="vert-move"></img>
        </div>
      </div>

      <div className="lnc_btmsection grid grid-cols-2">
        <div
          className="btm_content  w-full"
          data-aos="fade-right"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <div className="btc_parent flex flex-col items-center  ">
            <div className="underline btc_founder ">
              {allPostsData.section4.s4Underlined}
            </div>
            <div>
              {/* <h5 className="btc_forest">{allPostsData.section4.s4Header2}</h5> */}
              <h6 className="btc_gander">
                {allPostsData.section4.s4SubHeader2}
              </h6>
            </div>
            <div className="btc_para">{allPostsData.section4.s4Content}</div>
            <div className="btc_btn">
              <Link to="/allCollections">
                <button class="button_content btn1 btn-primary1">
                  Explore The Collection
                  <img
                    className="moving_arrow icon hoz_move"
                    src={MovingArrow}
                    alt="movingArrow"
                  />
                </button>
              </Link>
            </div>
          </div>
        </div>

        < div className="btm_images flex justify-end">
          <img
            src={urlFor(allPostsData.section4.s4img1).url()}
            alt="small_image"
            className="sm_image"
          />
          <img
            src={urlFor(allPostsData.section4.s4img2).url()}
            alt="heroimage"
            className="hero1_img"
          />
        </div>
      </div>

      <div className="jeans_container">
        <div className="underline jn_founder ">
          {allPostsData.section5.s5Header}
        </div>
        <div className="jn_btm  flex justify-around ">
          <div
            className="jm_img1"
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <img
              src={urlFor(allPostsData.section5.s5img1).url()}
              alt="hor_image"
            />
          </div>
          <div
            className="jn_para "
            data-aos="zoom-in"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            {allPostsData.section5.s5Text}
          </div>
          <div
            className="jn_img2"
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            <img
              src={urlFor(allPostsData.section5.s5img2).url()}
              alt="vert_Image"
              className={'leafImage'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingColl;
