import React, { useEffect, useState } from "react";
import "./accessPage.css";
import ArrowDown from "../../assets/arrow2.svg";
import Footer from "../Footer/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "../../client";
import { CircularProgress } from "@material-ui/core";
import { motion } from "framer-motion";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const AccessPage = () => {
  const [allPostsData, setAllPosts] = useState(null);
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    sanityClient
      .fetch(
        `*[_type=="about"]{
  title,
  by,
  description,
  section1{
    htext
  },
  section2{
    image1{
     asset->{
       _id,url
     }
    },
    year1,
    year1text,
    year2,
    year2text,

    image2{
     asset->{
       _id,url
     }
    },
    year3,
    year3text,
    year4,
    year4text,

    image3{
     asset->{
       _id,url
     }
    },
    year5,
    year5text,
    year6,
    year6text,

    image4{
     asset->{
       _id,url
     }
    },
    year7,
    year7text,
    year8,
    year8text,

    image5{
     asset->{
       _id,url
     }
    },
    year9,
    year9text,
    year10,
    year10text,    
  }
}`,
        {
          signal: signal,
        }
      )
      .then((data) => setAllPosts(data[0]))
      .catch(console.error);
    return function cleanup() {
      abortController.abort();
    };
  }, []);

  if (!allPostsData)
    return (
      <div className="load_div">
        <CircularProgress />
      </div>
    );
  AOS.init();
  return (
    <>
      <div className="about_container">
        {/* <div className="about" >
                    <div className="about-left lg:w-full lg:h-full lg:flex lg:flex-col lg:justify-center lg:pl-14 ">
                        <div className="acs_today ">Join The <br />Limited League</div>
                        <div className="digital mt-4">Get Your Access To The First Digital Denim Archive</div>
                        <div className="acs_register mt-12">
                            <Link to='/register'> <button class="button_content btn1 btn-primary1">Register With Us<img className="moving_arrow icon hoz_move" src={MovingArrow} alt="movingArrow" /></button></Link>
                        </div>
                    </div>

                    <div className="about-right lg:flex lg:flex-col lg:justify-center">
                        <div className="abr_1">
                            <div className="abr1_text ">
                                Become a part of a unique, seamless digital journey to procure your own denim collection.
                        </div>
                            <div className="abr1_img mt-1">
                                <img src={ArrowRight} alt="arrowright" />
                            </div>
                        </div>
                        <div className="abr_2 mt-4">
                            <div className="abr2_text">Raymond’s heritage archive and contemporary collections are ready to be a part of your next design story. Register (Free) to access them.</div>
                            <div className="abr1_img mt-1">
                                <img src={ArrowRight} alt="arrowright" />
                            </div>
                        </div>
                    </div>
                </div> */}
        <div className="access_center">
          {/* <div className="center_head">
                        Raymond UCO Denim
                        </div> */}
          {/* <div className="center_underline" /> */}
          <motion.div
            initial={{ y: "-400px" }}
            animate={{ y: 0 }}
            transition={{ delay: 0.5, type: "spring", stiffness: 50 }}
          >
            <div className="center_head1">{allPostsData.title}</div>
            <div className="center_head2">{allPostsData.by}</div>
          </motion.div>
          <div
            className="center_head3"
            // data-aos="zoom-in"
            // data-aos-mirror="false"
            // data-aos-once="true"
            // data-aos-offset="200"
            // data-aos-delay="50"
            // data-aos-duration="1000"
            // data-aos-easing="ease-in-out"
          >
            <motion.h3
              className="years-div"
              initial={{ x: "1500px" }}
              animate={{ x: 0 }}
              transition={{ delay: 0.4 }}
            >
              {" "}
              {allPostsData.description}
            </motion.h3>
          </div>
        </div>

        {/* <div className="acc_req">
                    <RequestPage />
                </div> */}

        {/* <div className="access_center cn2">
                    <div className="center_head">
                        Our Patners
            </div>
                    <div className="center_underline" />
                    <div className="center_head1">
                        quam vulputate dignissim
            </div>
                    <div className="center_head2" >
                        ultrices mi tempus imperdiet
            </div>
                </div> */}

        {/* <div className="brand_partners" data-aos="zoom-in" data-aos-mirror="false"
                    data-aos-once="true" data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out">
                    <div className="brand_div1"></div>
                    <div className="brand_div2">
                        <img src={Brand2} alt="brand2" />
                    </div>
                    <div className="brand_div3"></div>
                    <div className="brand_div4"></div>
                    <div className="brand_div5"></div>
                    <div className="brand_div6"></div>
                    <div className="brand_div7"></div>
                    <div className="brand_div8"></div>
                    <div className="brand_div9"></div>
                    <div className="brand_div10"></div>
                </div> */}

        <div
          className="access_bottom mt-2"
          data-aos="zoom-in"
          data-aos-mirror="false"
          data-aos-once="true"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <div className="access_bText">{allPostsData.section1.htext}</div>
          {/* <div className="access_bImg">
            <img className="vert-move" src={ArrowDown} alt="access_bImg" />
          </div> */}
        </div>
        <div className="ourJourneyText">Our journey so far</div>
        <div className="grid_layout">
          <div className="grid_div">
            <img src={urlFor(allPostsData.section2.image1).url()} alt="grid1" />
          </div>
          <div className="grid_text1">
            <div className="gt1_txt1" style={{margin:0,paddingLeft:30,paddingRight:30}}>
              <div className="year1">{allPostsData.section2.year1}</div>
              <div className="fame_text" style={{margin:0}}>{allPostsData.section2.year1text}</div>
            </div>
            <div className="gt1_text2" style={{margin:0,paddingLeft:30,paddingRight:30,marginTop:40}}>
              <div className="year1">{allPostsData.section2.year2}</div>
              <div className="fame_text" style={{margin:0,height:'fit-content'}}>{allPostsData.section2.year2text}</div>
            </div>
          </div>
          <div className="grid_div">
            <img src={urlFor(allPostsData.section2.image2).url()} alt="grid2" />
          </div>
          <div className="grid_div">
            <div className="grid_text1">
              <div className="gt1_txt1" style={{margin:0,paddingLeft:30,paddingRight:30}}>
                <div className="year1">{allPostsData.section2.year3}</div>
                <div className="fame_text" style={{marginTop:0}}>
                  {allPostsData.section2.year3text}
                </div>
              </div>
              <div className="gt1_text2" style={{margin:0,paddingLeft:30,paddingRight:30,marginTop:40}}>
                <div className="year1">{allPostsData.section2.year4}</div>
                <div className="fame_text" style={{marginTop:0}}>
                  {allPostsData.section2.year4text}
                </div>
              </div>
            </div>
          </div>
          <div className="grid_div">
            <img src={urlFor(allPostsData.section2.image3).url()} alt="grid3" />
          </div>
          <div className="grid_div">
            <div className="grid_text1 gt_d5" style={{margin:0,paddingLeft:30,paddingRight:30}}>
              <div className="gt1_txt1" style={{marginLeft:0}}>
                <div className="year1">{allPostsData.section2.year5}</div>
                <div className="fmm" style={{margin:0,height:'135px'}}>{allPostsData.section2.year5text}</div>
              </div>
              <div className="gt1_txt">
                <div className="year1">
                  {allPostsData.section2.year6}
                </div>
                <div className="fame_text" style={{marginTop:0}}>
                  {allPostsData.section2.year6text}
                </div>
              </div>
            </div>
          </div>
          <div className="grid_div">
            <img src={urlFor(allPostsData.section2.image4).url()} alt="grid4" />
          </div>
          <div className="grid_div">
            <div className="grid_text1" style={{padding:'0px 30px'}}>
              <div className="gt1_txt1 gt_d5" style={{marginLeft:0,marginRight:0,marginTop:0}}>
                <div className="year1" style={{}}>{allPostsData.section2.year7}</div>
                <div className="fame_text" style={{margin:0,height:'135px'}}>
                  {allPostsData.section2.year7text}
                </div>
              </div>
              <div className="gt1_text2" style={{marginLeft:0,marginRight:0}}>
                <div className="year1">{allPostsData.section2.year8}</div>
                <div className="fame_text" style={{margin:0}}>
                  {allPostsData.section2.year8text}
                </div>
              </div>
            </div>
          </div>
          <div className="grid_div">
            <img src={urlFor(allPostsData.section2.image5).url()} alt="grid5" />
          </div>
          <div className="grid_div">
            <div className="grid_text1">
              <div className="gt1_txt1 gt_d5" style={{margin:0,paddingLeft:30,paddingRight:30}}>
                <div className="year1">{allPostsData.section2.year9}</div>
                <div className="fame_text" style={{margin:0,height:'135px'}}>
                  {allPostsData.section2.year9text}
                </div>
              </div>
              <div className="gt1_txt1 gt_d5" style={{margin:0,paddingLeft:30,paddingRight:30}}>
                <div className="year1">{allPostsData.section2.year10}</div>
                <div className="fame_text" style={{margin:0,height:'fit-content'}}>
                  {allPostsData.section2.year10text}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="access_footer">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default AccessPage;
