import React, { useEffect, useState } from "react";
import "./requestPage.css";
import "../../assets/founders/style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import AOS from "aos";
import "aos/dist/aos.css";
import { CircularProgress } from "@material-ui/core";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const RequestPage = () => {
  AOS.init();
  const [allPostsData, setAllPosts] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="home"]{
            section2{
              s2Block,
              s2CarouselImages[]{
                asset->{_id,url
                }
              },
              s2Header,
              s2SubHeader
            }
        }`
      )
      .then((data) => {
        setAllPosts(data[0]);
      })
      .catch(console.error);
  }, []);
  if (!allPostsData)
    return (
      <div className="load_div">
        <CircularProgress />
      </div>
    );
  return (
    <div className="rdd_container2 mt-4 grid grid-cols-2">
      <div
        className="rdd_carousal flex justify-between items-center w-full h-full"
        data-aos="zoom-in"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <Carousel
          className="c2"
          infiniteLoop
          autoPlay
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
          showArrows={false}
          // centerMode
        >
          <img
            className="rdc_1"
            src={urlFor(allPostsData.section2.s2CarouselImages[0]).url()}
          />
          <img
            className="rdc_1"
            src={urlFor(allPostsData.section2.s2CarouselImages[1]).url()}
          />
          <img
            className="rdc_1"
            src={urlFor(allPostsData.section2.s2CarouselImages[2]).url()}
          />
        </Carousel>
      </div>

      <div className="rdd_content flex flex-col justify-center items-center h-full w-full">
        <div className="rdc_container">
          <div className="rdc_text1">
            <h5>{allPostsData.section2.s2Header} </h5>
          </div>

          <div className="flex flex-col items-center mt-5">
            <h6 className="rdc_coll"> {allPostsData.section2.s2SubHeader}</h6>
            <p className="rdc_fill">{allPostsData.section2.s2Block}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestPage;
