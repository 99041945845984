import React, { useEffect, useState } from "react";
import "./App.css";
import MainLanding from "./components/MainLanding/MainLanding";
import { Route, Switch } from "react-router";
import Sustainable from "./components/SustainablePage/Sustainable";
import Login from "./components/LoginPage/Login";
import "./App.css";
import Register from "./components/Register/Register";
import './components/GreenLine/GreenLine.css'
import AccessPage from "./components/AccessPage/AccessPage";
import Faq from "./components/FAQ/Faq";
import GreenLProduct from "./components/GreenLPoduct/GreenLProduct";
import LoadingGif from "./components/LoadingGif/LoadingGif";
import ScrollToTop from "./components/ScrollToTop";
import Nav2 from "./components/Nav2/Nav2";
import DropDown from "./components/Nav2/DropDown";
import ProductView from "./components/ProductView/ProductView";
import AllCollection from "./components/AllCollections/AllCollection";
import PrivateRoute from "./PrivateRoute";
import TC from "./components/T&C/TC";
import privacy from "./components/PrivacyPolicy/privacy";
import CheckoutPage from "./components/CartPage/CheckoutPage";
import MyProfile from "./components/Profile/profile";
import MyOrder from "./components/MyOrder/myOrder";


function App() {
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    const hideMenu = () => {
      if (window.innerWidth > 768 && isOpen) {
        setIsOpen(false);
      }
    };
    window.addEventListener("resize", hideMenu);
    return () => {
      window.removeEventListener("resize", hideMenu);
    };
  });

  return (
    <>
      {loading ? (
        <LoadingGif />
      ) : (
        <>
          <div className="App">
            <ScrollToTop />
            {/* <Navbar /> */}
            <Nav2 toggle={toggle} />
            <DropDown isOpen={isOpen} toggle={toggle} />
            <Switch>
              <Route exact path="/" component={MainLanding} />
              <PrivateRoute exact path="/sustain" component={Sustainable} />
              <PrivateRoute exact path="/myprofile" component={MyProfile} />
              <PrivateRoute exact path="/myorder" component={MyOrder} />
              <Route exact path="/login" component={Login} />
              <PrivateRoute exact path="/access" component={AccessPage} />
              <PrivateRoute exact path="/faq" component={Faq} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/tc" component={TC} />
              <Route exact path="/checkout" component={CheckoutPage} />
              <Route exact path="/privacy" component={privacy} />
              <Route exact path='/loading' component={LoadingGif} />
              <PrivateRoute
                exact
                path="/allCollections"
                component={AllCollection}
              />
              <PrivateRoute
                exact
                path="/product/:productId"
                component={ProductView}
              />
              <PrivateRoute
                exact
                path="/collection/:slug"
                component={GreenLProduct}
              />
            </Switch>
          </div>
        </>
      )}
    </>
  );
}

export default App;
