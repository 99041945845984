import React, { useEffect, useState } from "react";
import "./storyPage.css";
import MovingArrow from "../../assets/Icons and Buttons/buttonArrow.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import { CircularProgress } from "@material-ui/core";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const StoryPage = () => {
  const [allPostsData, setAllPosts] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="home"]{
              section6{
                s6Block1,
                s6Block2,
                s6Header,
                s6Images[]{
                  asset->{
                  _id,url
                  }
                }
              }
        }`
      )
      .then((data) => {
        setAllPosts(data[0]);
      })
      .catch(console.error);
  }, []);
  if (!allPostsData)
    return (
      <div className="load_div">
        <CircularProgress />
      </div>
    );
  AOS.init();
  return (
    <div className="story">
      <div className="story_headtext">
        <p className="story_heading">{allPostsData.section6.s6Header}</p>
      </div>
      <div className="story_button">
        <Link to="/sustain">
          {" "}
          <button class="button_content btn1 btn-primary1">
            Explore Sustainability{" "}
            <img
              className="moving_arrow icon hoz_move"
              src={MovingArrow}
              alt="movingArrow"
            />
          </button>
        </Link>
      </div>

      <div
        className="story_images grid gap-x-0"
        data-aos="zoom-in"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        <div className="story_image1">
          <img
            src={urlFor(allPostsData.section6.s6Images[0]).url()}
            alt="storyimg1"
          />
        </div>
        <div className="story_image2">
          <img
            className="story_img2"
            src={urlFor(allPostsData.section6.s6Images[1]).url()}
            alt="storyimg2"
          />
        </div>
        <div className="story_image3">
          <img
            src={urlFor(allPostsData.section6.s6Images[2]).url()}
            alt="storyimg3"
          />
        </div>
      </div>
      <div className="story_btm  grid grid-cols-2 mt-5 mb-5">
        <div
          className="st_btm1"
          data-aos="fade-right"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <h2 className="text-semibold">{allPostsData.section6.s6Block1}</h2>
        </div>
        <div
          className="st_btm2  mt-1"
          data-aos="fade-left"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <p>{allPostsData.section6.s6Block2}</p>
          {/* <div className="btm2_arr">
            <img src={ArrowRight} alt="arr_right" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default StoryPage;
