import styled from "styled-components";

export const Background = styled.div`
  width: 100%;
  height: 100%;
  //   box-shadow: 0 3px 5px 2px rgba(255, 255, 255, 0.3);
  //   background: rgba(105, 105, 105, 0.7);
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: flex-end;
`;

export const ModalWrapper = styled.div`
  width: 430px;
  height: 200px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  margin-right: 0.4rem;
  // overflow-y:scroll;
`;
