import React, { useEffect, useState } from "react";
import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "../../client";
import { Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const FeaturedProductComponent = (props) => {
  const [allPostsData, setAllPosts] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${props.slug}" && _type=="collectionPage"]{
          "product": products[]->{title,weight,fabricID,slug,productImages[0]{asset->{
                _id,url
          }}}
      }`
      )
      .then((data) => setAllPosts(data[0].product))
      .catch(console.error);
  }, [props.slug]);
  if (!allPostsData)
    return (
      <div className="load_div">
        <CircularProgress />
      </div>
    );

  // function for rendering products
  const renderProducts = allPostsData.map((collection, slug) => {
    return (
      <div className="col_container" key={slug}>
        <Link
          to={"/product/" + collection.slug.current}
          key={collection.slug.current}
        >
          <img
            src={urlFor(collection.productImages).url()}
            alt="proimg"
            className="coll_img"
          />
        </Link>
        <div className="coll_name mt-2">
          <h5>{collection.title}</h5>
        </div>
        <div className="coll_weight mt-1">Weight: {collection.weight} oz</div>
        <div className="coll_id mt-2">Fabric ID: {collection.fabricID}</div>
      </div>
    );
  });

  return (
    <>
      {/* <div className="prod_all flex flex-col"> */}
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 coll_div mp-4 pt-6">
          {renderProducts}
        </div>
      {/* </div> */}
    </>
  );
};

export default FeaturedProductComponent;
