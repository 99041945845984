import { Button } from "@material-ui/core";
import React from "react";
import { BiTrash } from "react-icons/bi";
import { connect } from "react-redux";
import {
  removeFromCart,
  subtractToHanger,
  subtractToYardage,
  addToHanger,
  addToYardage,
} from "../../redux/Shopping/shopping-actions";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import { useHistory } from "react-router-dom";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const CheckoutItem = ({
  cart,
  removeFromCart,
  subtractToHanger,
  subtractToYardage,
  addToYardage,
  addToHanger,
}) => {

  const history = useHistory();

  return (
    <div style={{overflowX:'auto'}}>
      <table>
        <tr className="tb_hd">
          <th className={'minWidth200 tableHead22'}>ITEM</th>
          <th className={'tableHead22'}>HANGER </th>
          <th className="tableHead22">YARDAGE</th>
        </tr>
        {cart.map((items, index) => {
          return (
            <tr>
              <td className="td_sum minWidth200">
                <div className="mainProductView">
                  <img
                    src={urlFor(items.img).url()}
                    className="tb_img"
                    alt="imggg"
                    style={{cursor:'pointer'}}
                    onClick={() => history.push("/product/"+items.id)}
                  />
                  <div className="nameAndDelete">
                    <p className="ml-4 productTextCart">{items.fabricID}</p>
                    <Button
                      className="cursor-pointer"
                      onClick={() => removeFromCart(items.id)}
                    >
                      <BiTrash className="ml-4 tr_btn cursor-pointer" />
                    </Button>
                  </div>
                </div>
              </td>
              <td>
                <div className="ck_hang  ">
                  <button
                    disabled={items.hangerQTY == 0}
                    onClick={() => subtractToHanger(items.id)}
                  >
                    -
                  </button>
                  <p className="mt-2">{items.hangerQTY}</p>
                  <button
                    onClick={() => addToHanger(items.id, items.name, items.img)}
                  >
                    +
                  </button>
                </div>
              </td>
              <td>
                <div className="ck_hang  ">
                  <button
                    disabled={items.yardageQTY == 0}
                    onClick={() => subtractToYardage(items.id)}
                  >
                    -
                  </button>
                  <p className="mt-2">{items.yardageQTY}</p>
                  <button
                    onClick={() =>
                      addToYardage(items.id, items.name, items.img)
                    }
                  >
                    +
                  </button>
                </div>
              </td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    cart: state.shop.cart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    removeFromCart: (id) => dispatch(removeFromCart(id)),
    subtractToHanger: (id) => dispatch(subtractToHanger(id)),
    subtractToYardage: (id) => dispatch(subtractToYardage(id)),
    addToHanger: (id, name, img) => dispatch(addToHanger(id, name, img)),
    addToYardage: (id, name, img) => dispatch(addToYardage(id, name, img)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutItem);
