import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SideNav from "../SideNav/SideNav";
import "./nav2.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaArrowCircleRight } from "react-icons/fa";
import { useAuth } from "../../contexts/AuthContext";
import RegisterPopUp from "../RegisterPopUp/RegisterPopUp";
import UCO from "../../assets/UCOlogo.png";
import { useMediaQuery } from "react-responsive";
import { DeviceSize } from "../DeviceSize";
import MobilePopUp from "../DeviceSize/MobilePopUp";

const Nav2 = ({ toggle }) => {
  const { currentUser } = useAuth();
  const [open, setOpen] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 3000);
  }, []);

  return (
    <>
      {currentUser ? (
        <SideNav />
      ) : (
        <>
          <nav
            className="newHeightHeader flex justify-between items-center self-center bg-black relative shadow-sm border-b border-red-700"
            role="navigation"
          >
            <div className="pl-6 text-red logo">
              <Link to="/">
                <img src={UCO} className="raymond_logo" alt="uco_logo" />
              </Link>
            </div>
            <div
              className="px-4 pl-2 cursor-pointer md:hidden "
              onClick={toggle}
            >
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="red"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div className="pr-8 mr-4 md:block hidden">
              <Link to="/allCollections" className="p-4 nc_1">
                COLLECTIONS
              </Link>
              <Link to="/sustain" className="p-4 nc_2">
                SUSTAINABILITY
              </Link>
              <Link to="/access" className="p-4 nc_3">
                ABOUT US
              </Link>
              <Link to="/faq" className="p-4 nc_4">
                CONTACT US
              </Link>
            </div>
            <div className="pr-6 md:block hidden">
              <Link to="/register" className="p-4 nc_reg">
                REGISTER
              </Link>
              <Link to="/login" className="p-4 nc_log">
                LOGIN
              </Link>
            </div>
          </nav>
          {/* {isMobile && (
            <MobilePopUp
              openMobile={openMobile}
              setOpenMobile={setOpenMobile}
            />
          )} */}
          {!isMobile && (
            <RegisterPopUp
              open={open}
              setOpen={setOpen}
              handleClose={handleClose}
            />
          )}
        </>
      )}
    </>
  );
};

export default Nav2;
