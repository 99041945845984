import React, { useEffect, useState } from "react";
import "./glCarousal.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from "react-bootstrap";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import { Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { Fade } from "react-awesome-reveal";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const Gcarousal = () => {
  const [allPostsData, setAllPosts] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="home"]{
            sec[]{
              collectionImage{
                asset->{
                    _id,url
                }
              },
              header,
              sub,
              slug
            }
        }`
      )
      .then((data) => {
        setAllPosts(data[0].sec);
      })
      .catch(console.error);
  }, []);
  if (!allPostsData)
    return (
      <div className="load_div">
        <CircularProgress />
      </div>
    );
  const renderProducts = allPostsData.map((product, slug) => {
    return (
      <Carousel.Item>
        <img
          className="d-block  m-auto cr_img"
          src={urlFor(product.collectionImage).url()}
          alt="First slide"
        />
        <Link
          to={"/collection/" + product.slug.current}
          key={product.slug.current}
        >
          <Carousel.Caption>
            <div className="cr_caption">
              <div className="cr_heading">Introducing</div>
              <div className="cr_gLine">{product.header}</div>
              <div className="cr_gSub">{product.sub}</div>
            </div>
          </Carousel.Caption>
        </Link>
      </Carousel.Item>
    );
  });
  return (
    <>
      <div className="green-line">
        <Fade delay={500}>
          <Carousel
            fade
            id="myCarousel"
            class="carousel slide"
            data-ride="carousel"
          >
            {renderProducts}
          </Carousel>
        </Fade>

      </div>
    </>
  );
};

export default Gcarousal;
