import styled from "styled-components";
import { MdClose } from "react-icons/md";


export const Background = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 0 3px 5px 2px rgba(255, 255, 255, 0.3);
  background: transparent;
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalWrapper = styled.div`
width: 453px;
height: 580px;
background: #FAF4EF 0% 0% no-repeat padding-box;
opacity: 1;
`;

export const ModalImg = styled.img`
  width: 100%;
  // height: 100%;
  height:450px;
  border-radius: 10px 0 0 10px;
  // mask-image: radial-gradient(at center center,#49494970 0%, #3030317c 100%);
  mask-image: rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.4);
  @media (max-width: 730px) {
    width: 0%;
    height: 0%;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;

  img {
    height: 150px;
    width: 150px;
    transition: all 0.5s ease-in-out;
    &:hover {
      transform: scale(1.1);
      transition: all 0.5s ease-in-out;
    }
  }

  h4 {
    // margin-bottom: 1rem;
    font-size: 25px;
    font-weight:700;
    color: rgba(0, 0, 0, 0.7);
    // line-height:5px;
  }
`;

export const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;

export const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  h2{
    font-size:15px;
  }
`;
export const BreakComponent = styled.div`
  margin: 0.5rem;
`;
export const BottomText = styled.div`
  text-align: center !important;
  margin-left: 2rem;
  margin-top: 2rem;
  font-size: 10px;
  font-weight:600;
  p {
    margin-top: 2rem;

  }
  span {
    color: blue;
    cursor: pointer;
  }
`;