import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import { Link } from "react-router-dom";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const CollectionDemo = () => {
  const params = useParams();
  const [allPostsData, setAllPosts] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${params.productId}" && _type=="product"]{
          recommend[]->{
            slug,
            productImages[0],
            title,
            weight,
            fabricID
          }
        }`
      )
      .then((data) => setAllPosts(data[0]["recommend"]))
      .catch(console.error);
  }, [params.productId]);
  if (!allPostsData)
    return (
      <div className="load_div">
        <CircularProgress />
      </div>
    );
  const renderProducts = allPostsData.map((product, slug) => {
    return (
      <div className="col_container" key={slug}>
        <Link
          to={"/product/" + product.slug.current}
          key={product.slug.current}
        >
          <img
            src={urlFor(product.productImages).url()}
            alt="proimg"
            className="coll_img"
          />
        </Link>
        <div className="coll_name mt-2">
          <h5>{product.title}</h5>
        </div>
        <div className="coll_weight mt-1">Weight: {product.weight} oz</div>
        <div className="coll_id mt-2">Fabric ID: {product.fabricID}</div>
      </div>
    );
  });

  return (
    <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 coll_demo">
      {renderProducts}
    </div>
  );
};

export default CollectionDemo;
