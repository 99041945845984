import React, { useEffect, useState } from "react";
import CollectionDemo from "../AllCollections/CollectionDemo";
import "./productPage.css";
// import { products } from './products.js';
import Footer from "../Footer/Footer";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  addToHanger,
  addToYardage,
} from "../../redux/Shopping/shopping-actions";
import { CircularProgress } from "@material-ui/core";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import CartPopup from "../CartPage/CartPopup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import { motion } from "framer-motion";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const ProductView = ({ addToHanger, addToYardage }) => {
  const params = useParams();
  const [allPostsData, setAllPosts] = useState(null);
  const [showCart, setShowCart] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadHng, setLoadHng] = useState(false);
  const [mainimg, setMainimg] = useState(null);

  // product carousal
  const changeimg = (e) => {
    setMainimg(e.target.src);
  };

  const notify = () =>
    toast("Item added to cart", {
      className: "msg",
    });

  const addHang = () => {
    setLoadHng(true);
    addToHanger(
      params.productId,
      allPostsData.title,
      allPostsData.productImages[0],
      allPostsData.fabricID
    );
    notify();
    setTimeout(() => {
      // setShowCart((prev) => !prev);
      setLoadHng(false);
    }, 1000);
  };

  const addYard = () => {
    setLoad(true);
    addToYardage(
      params.productId,
      allPostsData.title,
      allPostsData.productImages[0],
      allPostsData.fabricID
    );
    notify();
    setTimeout(() => {
      // setShowCart((prev) => !prev);
      setLoad(false);
    }, 1000);
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${params.productId}" && _type=="product"]{
                collection,
                colour,
                composition,
                description,
                fabricID,
                finish,
                quality,
                slug,
                title,
                type,
                weave,
                weight,
                width,
                productImages[]{
                    asset->{
                    _id,url
                }
            }
        }`
      )
      .then((data) => {
        setAllPosts(data[0]);
        setMainimg(data[0].productImages[0].asset.url);
      })
      .catch(console.error);
  }, [params.productId]);

  if (!allPostsData)
    return (
      <div className="load_div">
        <CircularProgress />
      </div>
    );

  return (
    <>
      <CartPopup showCart={showCart} setShowCart={setShowCart} />
      <div className="product_view">
        <div className="product_details">
          <div className="pro_images pt-5">
            <div className="fluid">
              <div className="imgdiv">
                <img
                  className="imgtag"
                  src={urlFor(allPostsData.productImages[0]).url()}
                  onClick={changeimg}
                />
                <img
                  className="imgtag"
                  src={urlFor(allPostsData.productImages[1]).url()}
                  onClick={changeimg}
                />
                <img
                  className="imgtag"
                  src={urlFor(allPostsData.productImages[2]).url()}
                  onClick={changeimg}
                />
                <img
                  className="imgtag"
                  src={urlFor(allPostsData.productImages[3]).url()}
                  onClick={changeimg}
                />
                <img
                  className="imgtag"
                  src={urlFor(allPostsData.productImages[4]).url()}
                  onClick={changeimg}
                />
                <img
                  className="imgtag"
                  src={urlFor(allPostsData.productImages[5]).url()}
                  onClick={changeimg}
                />
                <img
                  className="imgtag"
                  src={urlFor(allPostsData.productImages[6]).url()}
                  onClick={changeimg}
                />
                <img
                  className="imgtag"
                  src={urlFor(allPostsData.productImages[7]).url()}
                  onClick={changeimg}
                />
              </div>
              <div className="zoo ml-9">
                <motion.div
                  initial={{ y: "-1100px" }}
                  animate={{ y: 0 }}
                  transition={{ delay: 0.6, type: "spring", stiffness: 50 }}
                >
                  <InnerImageZoom
                    className="zom_1"
                    src={mainimg}
                    zoomSrc={mainimg}
                    // width={530}
                    // height={750}
                    zoomScale={2}
                    zoomType="hover"
                    hasSpacer={true}
                  />
                </motion.div>
              </div>
              <div className="imgdiv2">
                <img
                  className="imgtag"
                  src={urlFor(allPostsData.productImages[0]).url()}
                  onClick={changeimg}
                />
                <img
                  className="imgtag"
                  src={urlFor(allPostsData.productImages[1]).url()}
                  onClick={changeimg}
                />
                <img
                  className="imgtag"
                  src={urlFor(allPostsData.productImages[2]).url()}
                  onClick={changeimg}
                />
                <img
                  className="imgtag"
                  src={urlFor(allPostsData.productImages[3]).url()}
                  onClick={changeimg}
                />
                <img
                  className="imgtag"
                  src={urlFor(allPostsData.productImages[4]).url()}
                  onClick={changeimg}
                />
                <img
                  className="imgtag"
                  src={urlFor(allPostsData.productImages[5]).url()}
                  onClick={changeimg}
                />
                <img
                  className="imgtag"
                  src={urlFor(allPostsData.productImages[6]).url()}
                  onClick={changeimg}
                />
                <img
                  className="imgtag"
                  src={urlFor(allPostsData.productImages[7]).url()}
                  onClick={changeimg}
                />
              </div>
            </div>
          </div>

          <div className="pro_desc">
            <div className="pro_desc1">
              <motion.div
                initial={{ x: "700px" }}
                animate={{ x: 0 }}
                transition={{ delay: 0.6, type: "spring", stiffness: 50 }}
                className="pro_mainDesc"
              >
                <div className="pro_name">
                  <h5>{allPostsData.title} </h5>
                </div>
                {/* <div className="pro_select">Radio Buttons</div> */}
                <div className="pro_spec">{allPostsData.description}</div>
                <div className="pro_descButton">
                  <button
                    className="add_btn1 flex items-center justify-center"
                    onClick={addHang}
                  >
                    {loadHng ? (
                      <CircularProgress size={20} color="secondary" />
                    ) : (
                      " ADD HANGER"
                    )}
                  </button>
                  <button
                    className="add_btn2 flex items-center justify-center"
                    onClick={addYard}
                  >
                    {load ? (
                      <CircularProgress size={20} color="secondary" />
                    ) : (
                      "ADD YARDAGE"
                    )}
                  </button>
                </div>
                <ToastContainer
                  position="bottom-right"
                  autoClose={1000}
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </motion.div>
            </div>
            <div className={'pro_desc9'}>
              <Accordion elevation={0} style={{ backgroundColor: "transparent" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={'iconColor1'}/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: 800,
                      color: "black",
                      fontFamily:'Styrene A Web Reg'
                    }}
                  >
                    More Details
                </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <div className="pro_desc2">
                      <div className="desc2_quality">
                        <span className="text-lg font-semibold">
                          Fabric ID :
                  <span className="font-normal text-lg we_d">
                            {" "}
                            {allPostsData.quality}{" "}
                          </span>{" "}
                        </span>
                      </div>
                      <div className="desc2_weight">
                        <span className="text-lg font-semibold">
                          Weight :{" "}
                          <span className="font-normal text-lg we_d">
                            {" "}
                            {allPostsData.weight}{" "}OZ
                  </span>{" "}
                        </span>
                      </div>
                      <div className="desc2_width">
                        <span className="text-lg font-semibold">
                          Width :{" "}
                          <span className="font-normal text-lg we_d">
                            {" "}
                            {allPostsData.width}
                          </span>
                        </span>
                      </div>
                      <div className="desc2_color">
                        <span className="text-lg font-semibold">
                          Colour :{" "}
                          <span className="font-normal text-lg we_d">
                            {" "}
                            {allPostsData.colour}
                          </span>{" "}
                        </span>
                      </div>
                      <div className="desc2_finish">
                        <span className="text-lg font-semibold">
                          Finish :{" "}
                          <span className="font-normal text-lg we_d">
                            {" "}
                            {allPostsData.finish}
                          </span>{" "}
                        </span>
                      </div>
                      <div className="desc2_composition">
                        <span className="text-lg font-semibold">
                          Composition :{" "}
                          <span className="font-normal text-lg we_d">
                            {" "}
                            {allPostsData.composition}
                          </span>{" "}
                        </span>
                      </div>
                      <div className="desc2_weave">
                        <span className="text-lg font-semibold">
                          Weave :{" "}
                          <span className="font-normal text-lg we_d">
                            {" "}
                            {allPostsData.weave}
                          </span>{" "}
                        </span>
                      </div>
                      <div className="desc2_type">
                        <span className="text-lg font-semibold">
                          Type :
                  <span className="font-normal text-lg we_d">
                            {" "}
                            {allPostsData.type}
                          </span>{" "}
                        </span>
                      </div>
                    </div>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className={'pro_desc8'}>
              <div className="pro_desc2">
                <div className="desc2_quality">
                  <span className="text-lg font-semibold">
                    Fabric ID :
                  <span className="font-normal text-lg we_d">
                      {" "}
                      {allPostsData.quality}{" "}
                    </span>{" "}
                  </span>
                </div>
                <div className="desc2_weight">
                  <span className="text-lg font-semibold">
                    Weight :{" "}
                    <span className="font-normal text-lg we_d">
                      {" "}
                      {allPostsData.weight}{" "}OZ
                  </span>{" "}
                  </span>
                </div>
                <div className="desc2_width">
                  <span className="text-lg font-semibold">
                    Width :{" "}
                    <span className="font-normal text-lg we_d">
                      {" "}
                      {allPostsData.width}
                    </span>
                  </span>
                </div>
                <div className="desc2_color">
                  <span className="text-lg font-semibold">
                    Colour :{" "}
                    <span className="font-normal text-lg we_d">
                      {" "}
                      {allPostsData.colour}
                    </span>{" "}
                  </span>
                </div>
                <div className="desc2_finish">
                  <span className="text-lg font-semibold">
                    Finish :{" "}
                    <span className="font-normal text-lg we_d">
                      {" "}
                      {allPostsData.finish}
                    </span>{" "}
                  </span>
                </div>
                <div className="desc2_composition">
                  <span className="text-lg font-semibold">
                    Composition :{" "}
                    <span className="font-normal text-lg we_d">
                      {" "}
                      {allPostsData.composition}
                    </span>{" "}
                  </span>
                </div>
                <div className="desc2_weave">
                  <span className="text-lg font-semibold">
                    Weave :{" "}
                    <span className="font-normal text-lg we_d">
                      {" "}
                      {allPostsData.weave}
                    </span>{" "}
                  </span>
                </div>
                <div className="desc2_type">
                  <span className="text-lg font-semibold">
                    Type :
                  <span className="font-normal text-lg we_d">
                      {" "}
                      {allPostsData.type}
                    </span>{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pro_recommend font-bold mt-16 mx-auto underline">
          EXPLORE COLLECTION
        </div>
        <div className="recommend_demo">
          <CollectionDemo productId={params.productId} />
        </div>
        <div className="w-full h-1 bg-red-500" />
        {/* <div className="explore_col_text font-bold  underline mx-auto mt-5">
          EXPLORE COLLECTIONS
        </div>
        <div className="expl_coll">
          <ProductComponent />
        </div> */}
        <div className="pro_footer">
          <Footer />
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToHanger: (id, name, img, fabricID) => dispatch(addToHanger(id, name, img, fabricID)),
    addToYardage: (id, name, img, fabricID) => dispatch(addToYardage(id, name, img, fabricID)),
  };
};

export default connect(null, mapDispatchToProps)(ProductView);
