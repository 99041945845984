import * as actionTypes from "./shopping-types";

export const addToHanger = (itemID, name, img,fabricID) => {
  return {
    type: actionTypes.ADD_TO_HANGER,
    payload: {
      id: itemID,
      name: name,
      img: img,
      fabricID:fabricID
    },
  };
};
export const subtractToHanger = (itemID) => {
  return {
    type: actionTypes.SUBTRACT_TO_HANGER,
    payload: {
      id: itemID,
    },
  };
};
export const addToYardage = (itemID, name, img,fabricID) => {
  return {
    type: actionTypes.ADD_TO_YARDAGE,
    payload: {
      id: itemID,
      name: name,
      img: img,
      fabricID:fabricID
    },
  };
};
export const subtractToYardage = (itemID) => {
  return {
    type: actionTypes.SUBTRACT_TO_YARDAGE,
    payload: {
      id: itemID,
    },
  };
};

export const removeFromCart = (itemID) => {
  return {
    type: actionTypes.REMOVE_FROM_CART,
    payload: {
      id: itemID,
    },
  };
};

export const clearCart = () => {
  return {
    type: actionTypes.CLEAR_CART,
  };
};
