import React, { useEffect, useState } from "react";
import "./mainLanding.css";
import arrow from "../../assets/arrow1.svg";
import Collection from "../CollectionPage/Collection";
import StoryPage from "../StoryPage/StoryPage";
import Footer from "../Footer/Footer";
import RequestPage from "../RequestPage/RequestPage";
import TopButton from "../../assets/Icons and Buttons/topButton.svg";
import Gcarousal from "../GreenLine-Carousal/Gcarousal";
import Lslider from "../Lslider/Lslider";
import arrow2 from "../../assets/arrow2.svg";
import verticalImg from "../../assets/ScreenVertical.png";
import MovingArrow from "../../assets/Icons and Buttons/buttonArrow.svg";
import zoomIcon from "../../assets/Icons and Buttons/zoomIcon.svg";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import LandingColl from "../LandingCollections/LandingColl";
import { CircularProgress, Paper } from "@material-ui/core";
import { RellaxWrapper } from "react-rellax-wrapper";
import { useMediaQuery } from "react-responsive";
import { DeviceSize } from "../DeviceSize";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}
const MainLanding = () => {
  const [allPostsData, setAllPosts] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="home"]{
        section1{
            header,carouselimages[]{
              asset->{
                _id,url
              }
          }
        },
      }`
      )
      .then((data) => setAllPosts(data[0]))
      .catch(console.error);
  }, []);

  if (!allPostsData)
    return (
      <div className="load_div">
        <CircularProgress />
      </div>
    );

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: isMobile?2:4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
  };

  const scTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  const scBottom = () => {
    document.body.scrollTop = 100;
    document.documentElement.scrollTop = 100;
  };

  return (
    <div className="main_landing">
      <div className="heading">
        <div
          className="heading1_main"
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <RellaxWrapper speed={7}>
            <p className="heading_text sm:text-center md:text-center">
              {allPostsData.section1.header}
            </p>
          </RellaxWrapper>
        </div>
        <div className="arrow-pad">
          <RellaxWrapper speed={5}>
            <div className="arrow">
              <img className="vert-move" onClick={scBottom} src={arrow}></img>
            </div>
          </RellaxWrapper>
        </div>
      </div>
      <div>
        <div className="main_slider1">
          <Slider className="sl_main" {...settings}>
            <div className="slm sl_img1">
              <img
                src={urlFor(allPostsData.section1.carouselimages[0]).url()}
                alt="sl_img1"
                className={'dashboardImageScroll'}
              />
            </div>
            <div className="slm sl_img2">
              <img
                src={urlFor(allPostsData.section1.carouselimages[1]).url()}
                alt="sl_img1"
                className={'dashboardImageScroll'}
              />
            </div>

            <div className="slm sl_img3">
              <img
                src={urlFor(allPostsData.section1.carouselimages[2]).url()}
                alt="sl_img1"
                className={'dashboardImageScroll'}
              />
            </div>
            <div className="slm sl_img4">
              <img
                src={urlFor(allPostsData.section1.carouselimages[3]).url()}
                alt="sl_img1"
                className={'dashboardImageScroll'}
              />
            </div>
            <div className="slm sl_img5">
              <img
                src={urlFor(allPostsData.section1.carouselimages[4]).url()}
                alt="sl_img1"
                className={'dashboardImageScroll'}
              />
            </div>
            <div className="slm sl_img6">
              <img
                src={urlFor(allPostsData.section1.carouselimages[5]).url()}
                alt="sl_img1"
                className={'dashboardImageScroll'}
              />
            </div>
            <div className="slm sl_img7">
              <img
                src={urlFor(allPostsData.section1.carouselimages[6]).url()}
                alt="sl_img1"
                className={'dashboardImageScroll'}
              />
            </div>
            <div className="slm sl_img8">
              <img
                src={urlFor(allPostsData.section1.carouselimages[7]).url()}
                alt="sl_img1"
                className={'dashboardImageScroll'}
              />
            </div>
          </Slider>
        </div>
      </div>
      <div className="ln_rq">
        <RequestPage />
      </div>
      <Gcarousal />
      <LandingColl />
      <StoryPage />
      <div className="mn_footer">
        <Footer />
      </div>
    </div>
  );
};

export default MainLanding;
