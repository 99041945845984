import React, { useEffect } from "react";
import "./loadingGif.css";
import sanityClient from "../../client";
import { useState } from "react";
import { CircularProgress } from "@material-ui/core";
import UCO from '../../assets/UCOlogo.png';

const LoadingGif = () => {
  const [gif, setGif] = useState();
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="gif"]{
            gifFile{
                asset->{
                _id,url
                }
            }
        }`
      )
      .then((data) => setGif(data[0]))
      .catch(console.error);
  }, []);
  if (!gif)
    return (
      <div className="load_div">
        <CircularProgress />
      </div>
    );

  return (
    <div className="loading_gif">
      <div className="main_loading">
        <div className="gif_img">
          <img src={gif.gifFile.asset.url} alt="gif" />
        </div>
        <img src={UCO} alt="gif" className='uco' />
        <div className="l_text">KEEP ALL ASSUMPTIONS OUT. A NEW EXPERIENCE STARTS HERE</div>
        <div className="cp_right">@ RAYMOND UCO 2021. ALL RIGHTS RESERVED</div>
      </div>

    </div>
  );
};

export default LoadingGif;
