import React, { useState } from "react";
import "./Register.css";
import { BiChevronLeft } from "react-icons/bi";
import { HiOutlineUser } from "react-icons/hi";
import { BsChevronDown } from "react-icons/bs";
import { BiBuildingHouse } from "react-icons/bi";
import arrow from "../../assets/arrow2.svg";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import { firebase, functions } from "../../firebaseConfig"
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from 'axios';
import "sweetalert2/src/sweetalert2.scss";

const db = firebase.firestore();
const Register = () => {
  const [formData, setFormData] = useState({});


  const emailToAdmin = async (userData) => {

    var data = JSON.stringify({
      name: "Admin",
      email: "denim@raymond.in",
      header: "User Registration Request",
      subject: "User Registration",
      line1: "Your have received one new registration request from " + userData.email,
      line2: ""
    });

    var config = {
      method: 'post',
      url: 'https://us-central1-rayu-f9820.cloudfunctions.net/sendEmail',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log("EEEE", error);
      });
  }

  const onSuccess = async (userData) => {
    Swal.fire({
      title: "We have Recieved your request for Registration.",
      text: "Please check your mail for more details.",
    });
    emailToAdmin(userData);

    var data = JSON.stringify({
      name: userData.fname,
      email: userData.email,
      header: "User Registration Request",
      subject: "User Registration",
      line1: "Your request for registration has been received",
      line2: "You will be notified once the registration is confirmed."
    });

    var config = {
      method: 'post',
      url: 'https://us-central1-rayu-f9820.cloudfunctions.net/sendEmail',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log("EEEE", error);
      });
  };

  const updateInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendData = async () => {
    let userData = await db.collection("users").where("email", "==", formData.email).get()
      .then((data) => {
        let returnData = data.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))
        return returnData
      });
    if (userData.length > 0) {
      Swal.fire({
        // title: "We have Recieved your request for Registration.",
        text: "Email is already in use",
      });
    }
    else {
      db.collection("users").doc(formData.email).set({
        activated: false,
        onBoarded: false,
        email: formData.email,
        password: formData.password,
        fname: formData.fname,
        lname: formData.lname,
        company: formData.company,
        phone: formData.phone ? formData.phone : "",
        role: formData.role,
        time: new Date(),
      }).then(() => onSuccess(formData));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendData();
    setFormData({
      email: "",
      password: "",
      fname: "",
      company: "",
      lname: "",
      phone: "",
      role: "",
    });
  };
  return (
    <div>
      <div className="back-to-homepage">
        <BiChevronLeft />
        <Link to="/">
          <div>BACK TO HOME PAGE</div>
        </Link>
      </div>
      <div className="input-fields">
        <div className="register-header">
          Get Your Access To <br /> Denim Digital Tradeshow
        </div>
        <div className="down-icon">
          <img className="vert-move" src={arrow} onClick={() => window.scrollTo(0, 9999)} />
        </div>
        <span className="create-acc">Create An Account</span>
        <form onSubmit={handleSubmit} className={'formMainView'}>
          {" "}
          <div className="form_email mb-4 mt-4">
            <input type="text" id="subdomaintwo" value="Email" disabled />
            <input
              type="email"
              className="Email pl-2"
              name="email"
              placeholder="| Required field "
              value={formData.email ? formData.email.toLowerCase() : ''}
              onChange={(data) => setFormData({ ...formData, email: data.target.value.toLowerCase() }
                // onChange={(data) => console.log(data.target.value.toLowerCase())}
              )}
              required
            />
            <HiOutlineUser className="profile-icon" />
          </div>
          <div className="form_email mb-4 mt-3">
            <input type="text" id="subdomaintwo" value="Password" disabled />

            <input
              type="password"
              className="Password pl-2"
              name="password"
              placeholder="| Required field "
              value={formData.password}
              onChange={updateInput}
              required
            />
            <HiOutlineUser className="profile-icon" />
          </div>
          <div className="form_email mb-4 mt-3">
            <input type="text" id="subdomaintwo" value="First Name" disabled />
            <input
              type="text"
              className="FirstName pl-2"
              name="fname"
              placeholder="| First Name"
              value={formData.fname}
              onChange={updateInput}
              required
            />
            <HiOutlineUser className="profile-icon" />
          </div>
          <div className="form_email mb-4 mt-3">
            <input type="text" id="subdomaintwo" value="Last Name" disabled />
            <input
              type="text"
              className="LastName pl-2"
              name="lname"
              placeholder="| Required field"
              value={formData.lname}
              onChange={updateInput}
              required
            />
            <HiOutlineUser className="profile-icon text-red-600" />
          </div>
          <div className="form_email mb-4 mt-3">
            <input type="text" id="subdomaintwo" value="Company" disabled />
            <input
              type="text"
              className="LastName pl-2"
              name="company"
              placeholder="| Required field"
              value={formData.company}
              onChange={updateInput}
              required
            />
            <BiBuildingHouse className="profile-icon text-red-600" />
          </div>
          {/* <label>
            This is your personal @handle and it will be used to login and for
            mentions
          </label>
          <div>
            <input type="text" id="subdomaintwo" value="Username" disabled />
            <HiOutlineUser className="profile-icon" />
            <input
              type="text"
              className="Username"
              name="Username"
              placeholder="Username"
            />
          </div> */}
          <label>
            Private by default, we let you decide if you wish to display it in
            our members
          </label>
          <div className="form_email mb-4 mt-3">
            <input type="text" id="subdomaintwo" value="Phone" disabled />
            <input
              className="Phone pl-2"
              type="tel"
              name="phone"
              placeholder="| Phone Number (Optional)"
              value={formData.phone}
              onChange={updateInput}
            />
            <BsChevronDown className="profile-icon" />
          </div>
          <div className="form_email mb-4">
            <input type="text" id="subdomaintwo" value="Your Role" disabled />

            <input
              type="text"
              className="role pl-2"
              name="role"
              placeholder="| Buyers"
              value={formData.role}
              onChange={updateInput}
              required
            />
            <BsChevronDown className="profile-icon" />
          </div>
          <div>
            {" "}
            <label className="terms-conditions">
              By creating an account you are agreeing to the <br /> terms of
              service and privacy policy.
            </label>
          </div>
          <button type="submit" className="register-button">
            Register Now
          </button>
        </form>
        <p className="text-black">
          Already have an account?{" "}
          <Link className="text-black" to="/login">
            Login
          </Link>
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
