import React from 'react'
import './TC.css'
import Footer from "../Footer/Footer";

const TC = () => {
    return (
        <>

            <div className='tc mt-5'>

                <h1>COPYRIGHT POLICY, LINKING POLICY AND TRADEMARKS</h1>
                The Raymond Sites are protected by applicable copyright laws. Accordingly, you may not copy, distribute, modify, post or frame-in the Raymond Sites, including any text, graphics, video, audio, software code, user interface design or logos.
Unless you and Raymond have agreed otherwise, links from another web site to a Raymond Site must resolve to the top-level homepage of a Raymond domain (e.g., www.raymonddenim.net).
In order to avoid confusion if you do link from another web site to a Raymond top-level homepage, your web site, and the link itself, may not suggest that Raymond endorses, sponsors or is affiliated with any non-Raymond web site, entity, service or product, and may not make use of any Raymond trademarks or service marks other than those contained within the text of the link.<br />
                <br />
                <h1>THIRD PARTY CONTENT AND SITES</h1>
                The Raymond Sites include general news and information, commentary, quotes, and other subjects. Some of that content is supplied by companies that are not affiliated with Raymond ("Third Party Content"). Most Third Party Content is available through framed areas or through hyperlinks to third party web sites, and its source is clearly and prominently identified.
Although Third Party Content may be framed-in on the Raymond Sites or available through a link to a third-party site, Raymond does not prepare, edit, or endorse Third Party Content. Raymond therefore does not guarantee its accuracy, timeliness, completeness or usefulness, and is not responsible or liable for any content, advertising, products, or other materials on or available from third party sites. You will use Third Party Content only at your own risk.
In order to comply with applicable laws and regulations, Raymond may at times exclude content with respect to securities of issuers which are the subject of public offerings made available to Raymond, or otherwise.<br />
                <br />
                <h1>DISCLAIMER OF WARRANTIES</h1>
                Ownership Information presented on this website & the listed linked websites on this webpage, is the ownership of Raymond Limited unless otherwise indicated.<br />
                <br />
                <h1>LINKS TO OTHER SITES</h1>
                This website has many links to other websites, including other group companies in Raymond Limited. When you link to another site, you are still subject to the Disclaimer, Liability & Ownership Notice as published on this website.<br />
                <br />
                <h1>DISCLAIMER NOTICE</h1>
                This website and the other linked websites and the information they contain are provided on the public domain by Raymond Limited and its affiliates.<br />
                <br />
                This system is monitored to ensure proper operation, to verify the functioning of applicable security features, and for comparable purposes. Anyone using this system expressly consents to such monitoring. Raymond Limited Customers, Partners, Affiliates & Visitors interested in viewing Raymond Limited Public information are advised to use only listed websites, as published/linked officially through this website. Any ambiguity created on the internet due to similar domain, firm, corporation or context names; registered by third party/s to create ambiguous web presence so as to confuse the viewer/visitor; does not constitute endorsement, recommendation, or favoring by Raymond Limited<br />
                <br />
                Unauthorized attempts to use the same/similar trade, firm or corporation names to register other websites so as to create ambiguity or for illegal/unauthorized purposes & to modify any information stored on this system, to defeat or circumvent security features, or to utilize this system for other than its intended purposes are prohibited and may result in criminal prosecution.<br /><br />
                <h1>RESTRICTION OF LIABILITY</h1>
                We make no claims, promises or guarantees about the accuracy, completeness, or adequacy of the contents of this website and expressly disclaim liability for errors and omissions in the contents of this website. No warranty of any kind, implied, expressed or statutory, including but not limited to the warranties of non-infringement of third party rights, title, merchantability, fitness for a particular purpose and freedom from computer virus, is given with respect to the contents of this website or its hyperlinks to other Internet resources.<br />
                <br />Reference in this website to any specific commercial products, processes, or services, or the use of any trade, firm or corporation name is for the information and viewing convenience of the public.<br />
                <br />
                <h1>CHANGES TO RAYMOND SITES</h1>
                Unless otherwise agreed, RAYMOND may discontinue or modify the RAYMOND Sites at any time without prior notice to you, and you accept those modifications if you continue to use the RAYMOND Sites.<br />
                <br />
                <h1>DESCRIPTION OF SERVICES.</h1>
                The Services, including any updates, enhancements, new features, and/or the addition of any new Web properties, are subject to the TOU.<br/>
            </div>


            <Footer />
        </>

    )
}

export default TC
