import React, { useEffect, useState } from "react";
import "./profile.css";
import { auth, firebase, functions } from "../../firebaseConfig";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Footer from "../Footer/Footer";
import "sweetalert2/src/sweetalert2.scss";
const db = firebase.firestore();

function Profile() {
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [phone, setPhone] = useState("");
  const [cName, setCName] = useState("");
  const [role, setRole] = useState("");
  const [userData, setUserData] = useState(null);

  useEffect(async () => {
    const data = firebase.auth().currentUser;
    let userData = await db.collection("users").where("email", "==", data.email).get()
      .then((data) => {
        let returnData = data.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))
        return returnData
      });
    if (userData.length > 0) {
      let uData = userData[0];
      setUserData(uData);
      setFName(uData.fname);
      setLName(uData.lname);
      setCName(uData.company);
      setPhone(uData.phone);
      setRole(uData.role);
    }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    await db.collection("users").doc(userData.email).update({ 
      fname:fName,
      lname:lName,
      phone:phone,
      company:cName,
      role:role,
    })
    .then(() => Swal.fire({
      title: "Updated Successfully",
      text: "User details updated successfully",
    })
    );
  }

  return (
    <>
      <div>
        <div className={'profileText'}>PROFILE</div>
        <div className={'personalText'}>PERSONAL DETAILS</div>

        <form onSubmit={handleSubmit} className={'formMainViewProfile'}>
          <div className="form_email2 mb-4">
            <div className={'labelProfile'}>First Name</div>
            <input
              type="text"
              className="pl-2 Email newFontFamily"
              value={fName}
              onChange={(data) => setFName(data.target.value)}
              required
              placeholder="First name"
            />
          </div>
          <div className="form_email2 mb-4 mt-3">
            <div className={'labelProfile'}>Last Name</div>
            <input
              type="text"
              className="Email newFontFamily pl-2"
              value={lName}
              onChange={(data) => setLName(data.target.value)}
              required
              placeholder="Last name"
            />
          </div>
          <div className="form_email2 mb-4 mt-3">
            <div className={'labelProfile'}>Email Address</div>
            <input
              type="text"
              className="pl-2 Email newFontFamily"
              placeholder={'Email address'}
              disabled
              value={userData&&userData.email?userData.email:""}
            />
          </div>
          <div className="form_email2 mb-4 mt-3">
            <div className={'labelProfile'}>Phone</div>
            <input
              type="text"
              className="Email pl-2 newFontFamily"
              value={phone}
              onChange={(data) => setPhone(data.target.value)}
              placeholder="Phone number"
            />
          </div>
          <div className="form_email2 mb-4 mt-3">
            <div className={'labelProfile'}>Company Name</div>
            <input
              type={"text"}
              className="Email pl-2 newFontFamily"
              value={cName}
              onChange={(data) => setCName(data.target.value)}
              required
              placeholder="Company name"
            />
          </div>
          <div className="form_email2 mb-4 mt-3">
            <div className={'labelProfile'}>Role</div>
            <input
              type="text"
              className="Email pl-2 newFontFamily"
              value={role}
              onChange={(data) => setRole(data.target.value)}
            required
            placeholder="Your Role"
            />
          </div>
          <div style={{ display: 'flex', justifyContent: "center" }} className={''}>
            <button type="submit" className="register-button">
              UPDATE PROFILE
          </button></div>
          <div className={'changePassword'} onClick={() => {
            var user = firebase.auth();
            auth.sendPasswordResetEmail(userData.email).then(() => {
              Swal.fire({
                text: "Please check your email and reset your password"
              });
            })
          }}>want to change password?</div>
        </form>
        <Footer />
      </div>
    </>
  );
};

export default Profile;
