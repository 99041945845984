import React from 'react'
import Emptycart from './emptyCart.svg'

const EmptyCart = () => {
    return (
        <div className="empty_cart flex flex-col justify-center items-center text-center">
            <img src={Emptycart} alt="emptyCart" className="empty_img" />
            <p className="text-lg font-semibold">Your cart is Empty. <br />Add Some items to continue</p>
        </div>
    )
}

export default EmptyCart
