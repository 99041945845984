import { makeStyles, Paper } from "@material-ui/core";
import React from "react";
import "./checkout.css";
import CheckoutItem from "./CheckoutItem";
import { useHistory } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { connect } from "react-redux";
import { clearCart } from "../../redux/Shopping/shopping-actions";
import { firebase } from "../../firebaseConfig";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { useAuth } from "../../../src/contexts/AuthContext";

const db = firebase.firestore();
const useStyles = makeStyles((theme) => ({
  root: {
    shadows: ["none"],
  },
}));
const uploadData = (cart, email) => {
  const today = new Date();
  const date =
    today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();
  const time = today.getHours() + ":" + today.getMinutes();
  db.collection("orders").add({
    items: cart,
    status: "active",
    date: date,
    time: time,
    email: email,
  });
};

const CheckoutPage = ({ clearCart, cart }) => {
  const classes = useStyles();
  const history = useHistory()
  const { currentUser } = useAuth();
  return (
    <>
      <div className="checkout">
        <div className="ck_head">
          <h1>CHECKOUT</h1>
        </div>
        <div className="ck_details">
          <Paper square={true} className={classes.root}>
            <CheckoutItem />
          </Paper>
          {cart.length === 0 ? <button
            className="mt-4 mb-2 ck_btn flex items-center justify-around"
            onClick={() => {
              history.push('/allCollections')
            }}
          >
            Go Shopping <BsArrowRight />
          </button> : <button
            className="mt-4 mb-2 ck_btn flex items-center justify-around"
            onClick={() => {
              uploadData(cart, currentUser.email);
              clearCart();
              Swal.fire({
                title: "We Have Recieved Your Order",
                text: "Our team will contact you shortly",
              });
              setTimeout(() => {
                history.push('/')
              }, 2000)
            }}
          >
            Checkout <BsArrowRight />
          </button>}

        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    cart: state.shop.cart,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    clearCart: () => dispatch(clearCart()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
