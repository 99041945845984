import React, { useEffect, useState } from "react";
import "./sustainable.css";
import arrow from "../../assets/arrow2.svg";
import Footer from "../Footer/Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sanityClient from "../../client";
import imageUrlBuilder from "@sanity/image-url";
import gif from "./Raymond3.gif";
import { CircularProgress } from "@material-ui/core";
import { motion } from 'framer-motion';
import { useMediaQuery } from "react-responsive";
import { DeviceSize } from "../DeviceSize";


const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const Sustainable = () => {
  const [allPostsData, setAllPosts] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="sustainability"]{
  header,
  mainImage{
   asset->{
    _id,url
   }
  },
  sub1,
  sub2,
  header2,
  desc,
  section1{
    description,
    header,
    image{
     asset->{
      _id,url
     }
    }
  },
  section2{
    description,
    header,
    image{
     asset->{
      _id,url
     }
    }
  },
  section3{
    description,
    header,
    image{
     asset->{
      _id,url
     }
    }
  },
  section4{
    description,
    header,
    image{
     asset->{
      _id,url
     }
    }
  },
  section5{
    description,
    header,
    image{
     asset->{
      _id,url
     }
    }
  },
  section6{
    description,
    header,
    image{
     asset->{
      _id,url
     }
    }
  },
  images{
    description1,
    description2,
    description3,
    description4,
    header1,
    header2,
    header3,
    header4,
    backgroundimages{
      asset->{
      _id,url
      }
    }
  },
  quote{
    author,
    text
  },
  certificates{
  title1,
  by1,
  description1,
  title2,
  by2,
  description2,
  title3,
  by3,
  description3,
  image1{
  asset->{
  _id,url
  }},
  image2{
  asset->{
  _id,url
  }},
  image3{
  asset->{
  _id,url
  }},
},
  last{
    uText,
    Lhead1,
    Ltitle,
    cont1,
    cont2,
    cont3,
    cont4,
    head1,
    head2,
    head3,
    head4,
    sub1,
    sub2,
    sub3,
    sub4,
    backImage{
    asset->{
    _id,url
    }}
  }  
}`
      )
      .then((data) => setAllPosts(data[0]))
      .catch(console.error);
  }, []);

  if (!allPostsData)
    return (
      <div className="load_div">
        <CircularProgress />
      </div>
    );
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: isMobile?1.5:4,
    slidesToScroll: 1,
    autoplay: true,
    speed: isMobile?6000:2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
  };

  return (
    <div className="sus_container">
      {/* <div className="sus_top">
        <div className="sus_ttext">Commodo Consequat</div>
      </div> */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
        className="sus_mainImg"
        style={{
          backgroundImage: `url(${urlFor(allPostsData.mainImage).url()})`,
        }}
      >
        <motion.div
          initial={{ y: '-800px' }}
          animate={{ y: 0 }}
          transition={{ delay: 1, type: "spring", stiffness: 30 }}
          className="mainImg_head"
        // data-aos="zoom-in"
        // data-aos-offset="200"
        // data-aos-delay="50"
        // data-aos-duration="1000"
        // data-aos-easing="ease-in-out"
        >
          {allPostsData.header}
        </motion.div>
      </motion.div>

      <div className="sus_grid">
        <div className="sus_grid1">
          <div
            className="sd_1"
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-in-out"
          >
            {allPostsData.sub1}
          </div>
          {/* <div className="sd_icon">
            <img src={sidearr} alt="sideIcon" />
          </div> */}
        </div>

        <div
          className="sus_grid2"
          data-aos="fade-left"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          {allPostsData.sub2}
        </div>
      </div>

      <div
        className="access_center1"
        data-aos="zoom-in"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
      >
        {/* <div className="center_h1">Raymond UCO Presents</div> */}

        <div className="center_head1">{allPostsData.header2}</div>
        <div className="center_head2">{allPostsData.desc}</div>
      </div>

      <div className="arrow-button">
        <div className="arrow2">
          <img src={arrow} alt="arrow" className="vert-move"></img>
        </div>
      </div>

      <div className="sus_carousal">
        <Slider className="caro_container" {...settings}>
          <div className="cro_box1 flex flex-col">
            <img
              src={urlFor(allPostsData.section1.image).url()}
              alt="caroimg1"
              className="carImg1"
            />
            <div className="cro_underline mt-4" />
            <div className="cro_content1 mt-4">
              {allPostsData.section1.description}
            </div>
          </div>

          <div className="cro_box1 flex flex-col">
            <img
              src={urlFor(allPostsData.section2.image).url()}
              alt="caroImg2"
              className="carImg2"
            />
            <div className="cro_underline mt-4" />
            <div className="cro_content2 mt-4">
              {allPostsData.section2.description}
            </div>
          </div>

          <div className="cro_box3 flex flex-col">
            <img
              src={urlFor(allPostsData.section3.image).url()}
              alt="caroImg2"
              className="carImg2"
            />
            <div className="cro_underline mt-4" />
            <div className="cro_content3 mt-4">
              {allPostsData.section3.description}
            </div>
          </div>
          <div className="cro_box4 flex flex-col">
            <img
              src={urlFor(allPostsData.section4.image).url()}
              alt="caroImg2"
              className="carImg2"
            />
            <div className="cro_underline mt-4" />
            <div className="cro_content4 mt-4">
              {allPostsData.section4.description}
            </div>
          </div>
          <div className="cro_box4 flex flex-col">
            <img
              src={urlFor(allPostsData.section5.image).url()}
              alt="caroImg2"
              className="carImg2"
            />
            <div className="cro_underline mt-4" />
            <div className="cro_content5 mt-4 ">
              {allPostsData.section5.description}
            </div>
          </div>
          <div className="cro_box4 flex flex-col">
            <img
              src={urlFor(allPostsData.section6.image).url()}
              alt="caroImg2"
              className="carImg2"
            />
            <div className="cro_underline mt-4" />
            <div className="cro_content3 mt-4">
              {allPostsData.section6.description}
            </div>
          </div>
        </Slider>
      </div>

      <div
        className="vd_grid"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="1000"
        data-aos-easing="ease-in-out"
        style={{
          backgroundImage: `url(${urlFor(
            allPostsData.images.backgroundimages
          ).url()})`,
        }}
      >
        <div className="vd_tcontainer">
          <div className="vd_1">
            <div className="vd_a">{allPostsData.images.header1}</div>
            <div className="vd_b">{allPostsData.images.description1}</div>
          </div>
          <div className="vd_2">
            <div className="vd_c ">{allPostsData.images.header2}</div>
            <div className="vd_d">{allPostsData.images.description2}</div>
          </div>
          <div className="vd_4">
            <div className="vd_e mt-3">{allPostsData.images.header3}</div>
            <div className="vd_h">{allPostsData.images.description3}</div>
          </div>
          <div className="vd_4">
            <div className="vd_g mt-3">{allPostsData.images.header4}</div>
            <div className="vd_h">{allPostsData.images.description4}</div>
          </div>
        </div>
      </div>

      <div data-aos="fade-up" data-aos-anchor-placement="center-bottom">
        <div className="fd_div">{allPostsData.quote.author}</div>
        <div className="fd_bottom">{allPostsData.quote.text}</div>
      </div>

      <div
        className="ray_cert"
        // data-aos="fade-up"
        // data-aos-anchor-placement="center-bottom"
        // data-aos-delay="50"
        // data-aos-duration="10"
      >
        <div className="ray_cert1 flex justify-center items-center">
          <img src={gif} alt="gif" className="ray_gif" />
        </div>

        <div className="ray_cert2">
          <div
            className="cert2_a"
            style={{
              backgroundImage: `url(${urlFor(
                allPostsData.certificates.image1
              ).url()})`,
            }}
          ></div>
          <div className="cert2_d">
            <div className="certd-header">
              <div className="hd2_1">{allPostsData.certificates.title1}</div>
              <div className="hd2_2">{allPostsData.certificates.by1}</div>
            </div>
            <div className="certd2_para">
              {allPostsData.certificates.description1}
            </div>
          </div>
          <div
            className="cert2_c"
            style={{
              backgroundImage: `url(${urlFor(
                allPostsData.certificates.image2
              ).url()})`,
            }}
          ></div>
          <div className="cert2_d">
            <div className="certd-header">
              <div className="hd2_1">{allPostsData.certificates.title2}</div>
              <div className="hd2_2">{allPostsData.certificates.by2}</div>
            </div>
            <div className="certd2_para">
              {allPostsData.certificates.description2}
            </div>
          </div>
          <div
            className="cert2_e"
            style={{
              backgroundImage: `url(${urlFor(
                allPostsData.certificates.image3
              ).url()})`,
            }}
          ></div>
          <div className="cert2_f">
            <div className="cert2_d">
              <div className="certd-header">
                <div className="hd2_1">{allPostsData.certificates.title3}</div>
                <div className="hd2_2">{allPostsData.certificates.by3}</div>
              </div>
              <div className="certd2_para">
                {allPostsData.certificates.description3}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="fd_div">{allPostsData.last.uText}</div> */}
      <div className="center_head1">{allPostsData.last.Ltitle}</div>
      <div className="center_head2">{allPostsData.last.head1}</div>

      <div className="arrow-button">
        <div className="arrow2">
          <img src={arrow} alt="arrow" className="vert-move"></img>
        </div>
      </div>

      <div
        className="btm_grid grid grid-cols-2 mt-3"
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        data-aos-delay="30"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <div className="btg_box1 grid grid-cols-2 grid-rows-2">
          <div className=" newPaddingLeft1 w-full h-full  flex flex-col justify-around border-b-2 border-l-2 border-r-2 border-red-600">
            <div className="newML2">
              <div className="gd_ctn">
                <h5>{allPostsData.last.Lhead1}</h5>
              </div>
              <div className="gd_tempus mt-3">{allPostsData.last.sub1}</div>
            </div>

            <div className="ml-2 gd_certified ">
              <h6>{allPostsData.last.cont1}</h6>
            </div>
          </div>

          <div className="w-full h-full flex flex-col justify-around newPaddingLeft1  border-b-2  border-red-600 ">
            <div className="newML2">
              <div className="gd_ctn">
                <h5>{allPostsData.last.head2}</h5>
              </div>
              <div className="gd_tempus mt-3">{allPostsData.last.sub2}</div>
            </div>

            <div className="ml-2 gd_certified ">
              <h6>{allPostsData.last.cont2}</h6>
            </div>
          </div>

          <div className="w-full h-full flex flex-col justify-around newPaddingLeft1  border-l-2  border-r-2 border-red-600 borderB2PX">
            <div className="newML2">
              <div className="gd_ctn">
                <h5>{allPostsData.last.head3}</h5>
              </div>
              <div className="gd_tempus mt-3">{allPostsData.last.sub3}</div>
            </div>

            <div className="ml-2 gd_certified ">
              <h6>{allPostsData.last.cont3}</h6>
            </div>
          </div>

          <div className="w-full h-full flex flex-col justify-around newPaddingLeft1  border-red-600">
            <div className="newML2">
              <div className="gd_ctn">
                <h5>{allPostsData.last.head4}</h5>
              </div>
              <div className="gd_tempus mt-3">{allPostsData.last.sub4}</div>
            </div>

            <div className="ml-2 gd_certified ">
              <h6>{allPostsData.last.cont4} </h6>
            </div>
          </div>
        </div>

        <div
          className="btg_box2"
          style={{
            backgroundImage: `url(${urlFor(
              allPostsData.last.backImage
            ).url()})`,
          }}
        ></div>
      </div>
      <Footer />
    </div>
  );
};

export default Sustainable;
