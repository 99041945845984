import React, { useEffect, useState } from "react";
import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "../../client";
import { Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

const ProductComponent = () => {
  const [allPostsData, setAllPosts] = useState(null);
  const [allCast, setAllCast] = useState(null);
  const [allColor, setAllColor] = useState(null);
  const [allComposition, setAllComposition] = useState(null);
  const [allGender, setAllGender] = useState(null);
  const [allSeason, setAllSeason] = useState(null);
  const [allWeight, setAllWeight] = useState(null);
  const [allWeive, setAllWeive] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(null);

  const [cast, setCast] = useState("");
  const [color, setColor] = useState("");
  const [composition, setComposition] = useState("");
  const [gender, setGender] = useState("");
  const [season, setSeason] = useState("");
  const [weight, setWeight] = useState("");
  const [weive, setWeive] = useState("");
  const [set, isSet] = useState(false);

  const handleCastChange = (event) => {
    setCast(event.target.value);
    setCurrentFilter(event.target.value);
    isSet(true);
  };
  const handleColorChange = (event) => {
    setColor(event.target.value);
    setCurrentFilter(event.target.value);
    isSet(true);
  };
  const handleCompositionChange = (event) => {
    setComposition(event.target.value);
    setCurrentFilter(event.target.value);
    isSet(true);
  };
  const handleGenderChange = (event) => {
    setGender(event.target.value);
    setCurrentFilter(event.target.value);
    isSet(true);
  };
  const handleSeasonChange = (event) => {
    setSeason(event.target.value);
    setCurrentFilter(event.target.value);
    isSet(true);
  };
  const handleWeightChange = (event) => {
    setWeight(event.target.value);
    setCurrentFilter(event.target.value);
    isSet(true);
  };
  const handleWeiveChange = (event) => {
    setWeive(event.target.value);
    setCurrentFilter(event.target.value);
    isSet(true);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    if (set == true) {

      let query = "";
      if (cast) {
        query += "'" + cast + "' in tags &&";
      }
      if (weight) {
        query += "'" + weight + "' in tags &&";
      }
      if (weive) {
        query += "'" + weive + "' in tags &&";
      }
      if (color) {
        query += "'" + color + "' in tags &&";
      }
      if (composition) {
        query += "'" + composition + "' in tags &&";
      }
      if (season) {
        query += "'" + season + "' in tags &&";
      }
      if (gender) {
        query += "'" + gender + "' in tags &&";
      }
      if (query != "") {
        query = query.slice(0, -2);
      }

      sanityClient
        .fetch(`*[_type=="product"][${query}]`, {
          signal: signal,
        })
        .then((data) => setAllPosts(data))
        .catch(console.error);
    } else {
      sanityClient
        .fetch(`*[_type=="product"]`)
        .then((data) => setAllPosts(data))
        .catch(console.error);
    }

    sanityClient
      .fetch(
        `*[_type=="filters"]{
        cast,
        color,
        composition,
        gender,
        season,
        weight,
        weive
      }`,
        {
          signal: signal,
        }
      )
      .then((data) => {
        setAllCast(data[0].cast);
        setAllColor(data[0].color);
        setAllComposition(data[0].composition);
        setAllGender(data[0].gender);
        setAllSeason(data[0].season);
        setAllWeight(data[0].weight);
        setAllWeive(data[0].weive);
      })
      .catch(console.error);

    return function cleanup() {
      abortController.abort();
    };
  }, [currentFilter, set]);

  if (
    !allPostsData ||
    !allCast ||
    !allColor ||
    !allComposition ||
    !allGender ||
    !allSeason ||
    !allWeight ||
    !allWeive
  )
    return (
      <div className="load_div">
        <CircularProgress />
      </div>
    );

  // function for rendering products
  const renderProducts = allPostsData.map((collection, slug) => {
    return (
      <div className="col_container" key={slug}>
        <Link to={"/product/" + collection.slug.current}>
          <img
            src={urlFor(collection.productImages[0]).url()}
            alt="proimg"
            className="coll_img"
          />
        </Link>
        <div className="coll_name">
          <h5>{collection.title}</h5>
        </div>
        <div className="coll_weight mt-1">Weight: {collection.weight} oz</div>
        <div className="coll_id mt-2">Fabric ID: {collection.fabricID}</div>
      </div>
    );
  });
  const renderCast = allCast.map((cast, slug) => {
    return (
      <option value={cast} key={slug}>
        {cast}
      </option>
    );
  });
  const renderColor = allColor.map((color, slug) => {
    return (
      <option value={color} key={slug}>
        {color}
      </option>
    );
  });
  const renderComposition = allComposition.map((composition, slug) => {
    return (
      <option value={composition} key={slug}>
        {composition}
      </option>
    );
  });
  const renderGender = allGender.map((gender, slug) => {
    return (
      <option value={gender} key={slug}>
        {gender}
      </option>
    );
  });
  const renderSeason = allSeason.map((season, slug) => {
    return (
      <option value={season} key={slug}>
        {season}
      </option>
    );
  });
  const renderWeight = allWeight.map((weight, slug) => {
    return (
      <option value={weight} key={slug}>
        {weight}
      </option>
    );
  });
  const renderWeive = allWeive.map((weive, slug) => {
    return (
      <option value={weive} key={slug}>
        {weive}
      </option>
    );
  });

  return (
    <>
      <div className="drop_down flex justify-between">
        <div className="flex items-start newDivInAll">
          <div className="mr-4 dp_cast ">
            <select value={cast} onChange={handleCastChange} name="cast">
              <option value="" disabled hidden>
                Category
              </option>
              {renderCast}
            </select>
          </div>
          {/* <div className="mr-5 dp_cast flex"><p>Cast</p> <IoIosArrowDown className="down-a" color="red" /></div> */}
          <div className="mr-4 dp_weight">
            <select value={weight} onChange={handleWeightChange} name="weight">
              <option value="" disabled hidden>
                Weight
              </option>
              {renderWeight}
            </select>
          </div>
          <div className="mr-4 dp_weave">
            <select value={weive} onChange={handleWeiveChange} name="weave">
              <option value="" disabled hidden>
                Weave
              </option>
              {renderWeive}
            </select>
          </div>
          <div className="mr-4 dp_color">
            <select value={color} onChange={handleColorChange} name="color">
              <option value="" disabled hidden>
                Color
              </option>
              {renderColor}
            </select>
          </div>
          <div className="mr-4 dp_composition">
            <select
              value={composition}
              onChange={handleCompositionChange}
              name="composition"
            >
              <option value="" disabled hidden>
                Composition
              </option>
              {renderComposition}
            </select>
          </div>
        </div>
        <div className="flex dd_right items-start justify-between">
          <div className="dp_gender mr-4">
            <select value={season} onChange={handleSeasonChange} name="season">
              <option value="" disabled hidden>
                Season
              </option>
              {renderSeason}
            </select>
          </div>
          <div className="dp_gender mr-4">
            <select value={gender} onChange={handleGenderChange} name="gender">
              <option value="" disabled hidden>
                Gender
              </option>
              {renderGender}
            </select>
          </div>
          <div className="dp_clear flex justify-center">
            <button
              onClick={() => {
                isSet(false);
                setCast("");
                setColor("");
                setComposition("");
                setGender("");
                setSeason("");
                setWeive("");
                setWeight("");
              }}
            >
              Clear Filter
            </button>
          </div>
        </div>
      </div>
      <div className={'filterMAIN'}>
        <Accordion elevation={0} style={{ backgroundColor: "transparent" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={'iconColor1'} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 800,
                color: "black",
                fontFamily: 'Styrene A Web Reg'
              }}
            >
              Apply Filters
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className={"filterGridSYSTEM"}>
                <div className="mr-4 dp_cast ">
                  <select value={cast} onChange={handleCastChange} name="cast">
                    <option value="" disabled hidden>
                      Category
                    </option>
                    {renderCast}
                  </select>
                </div>
                <div className="mr-4 dp_weight">
                  <select value={weight} onChange={handleWeightChange} name="weight">
                    <option value="" disabled hidden>
                      Weight
                    </option>
                    {renderWeight}
                  </select>
                </div>
                <div className="mr-4 dp_weave">
                  <select value={weive} onChange={handleWeiveChange} name="weave">
                    <option value="" disabled hidden>
                      Weave
                    </option>
                    {renderWeive}
                  </select>
                </div>
                <div className="mr-4 dp_color">
                  <select value={color} onChange={handleColorChange} name="color">
                    <option value="" disabled hidden>
                      Color
                    </option>
                    {renderColor}
                  </select>
                </div>
                <div className="mr-4 dp_composition">
                  <select
                    value={composition}
                    onChange={handleCompositionChange}
                    name="composition"
                  >
                    <option value="" disabled hidden>
                      Composition
                    </option>
                    {renderComposition}
                  </select>
                </div>
                <div className="dp_gender mr-4">
                  <select value={season} onChange={handleSeasonChange} name="season">
                    <option value="" disabled hidden>
                      Season
              </option>
                    {renderSeason}
                  </select>
                </div>
                <div className="dp_gender mr-4">
                  <select value={gender} onChange={handleGenderChange} name="gender">
                    <option value="" disabled hidden>
                      Gender
              </option>
                    {renderGender}
                  </select>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '95%' }}>
          <div className={'CFTEXT'} onClick={() => {
            isSet(false);
            setCast("");
            setColor("");
            setComposition("");
            setGender("");
            setSeason("");
            setWeive("");
            setWeight("");
          }}>Clear Filter</div>
        </div>
      </div>
      <div className="prod_all flex flex-col">
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 coll_div">
          {renderProducts}
        </div>
        {/* <div className="back_next flex justify-between items-center text-red-500">
          <button>Back</button>
          <button>Next</button>
        </div> */}
      </div>
    </>
  );
};

export default ProductComponent;
