import React, { useState } from "react";
import request from "request";
import "./footer.css";
import { FaInstagram } from "react-icons/fa";
import { AiFillLinkedin } from "react-icons/ai";
import TopButton from "../../assets/Icons and Buttons/topButton.svg";
import { Link } from "react-router-dom";
import InputArrow from "../../assets/Icons and Buttons/Group 2839.svg";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import axios from 'axios';

import { firebase, functions } from "../../firebaseConfig";

const { REACT_APP_SEND_GRID } = process.env;

const Footer = () => {
  const [email, setEmail] = useState();

  var options = {
    method: "PUT",
    url: "https://api.sendgrid.com/v3/marketing/contacts",
    headers: {
      "content-type": "application/json",
      authorization: "Bearer " + REACT_APP_SEND_GRID,
    },
    body: {
      contacts: [
        {
          email: email,
        },
      ],
    },
    json: true,
  };

  const onChange = (event) => {
    setEmail(event.target.value);
  };

  const sendEmailToUser = async () => {
    var data = JSON.stringify({
      name: "User",
      email: email,
      header: "Subscription Request",
      subject: "Subscription Request",
      line1: "Thank you for subscribing to us, we will keep you posted with our latest updates.",
      line2: ""
    });

    var config = {
      method: 'post',
      url: 'https://us-central1-rayu-f9820.cloudfunctions.net/sendEmail',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log("EEEE", error);
      });
  }

  const sendEmailToAdmin = async () => {

    var data = JSON.stringify({
      name: "Admin",
      email: "denim@raymond.in",
      header: "Subscription Request",
      subject: "Subscription Request",
      line1: "One new user " + email + " subscribed to your site.",
      line2: ""
    });

    var config = {
      method: 'post',
      url: 'https://us-central1-rayu-f9820.cloudfunctions.net/sendEmail',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log("EEEE", error);
      });
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    sendEmailToUser();
    sendEmailToAdmin();
    request(options, function (error, response, body) {
      if (error) throw new Error(error);
      Swal.fire({
        title: "We have Recieved your request for receiving updates.",
        text:
          "You will be receiving latest updates on your requested email-id.",
      });
    });
    setEmail("");
  };
  const scTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };
  return (
    <div style={{ width: '100vw' }}>
      <div className="top_button">
        <img
          id="topSec"
          onClick={scTop}
          className="vert-move"
          src={TopButton}
          alt="topButton"
        />
      </div>
      <div className="footer grid grid-rows-2">
        <div
          className="foot_1 border-b border-red-500"
          style={{ maxWidth: '100vw', minWidth: '100vw' }}
          data-aos="zoom-in"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <div className="foot_news ">Newsletter</div>
          {/* border-b border-red-500  */}
          <div className="foot_subss mt-4">
            Subscribe to get the latest Updates
          </div>
          <form className="input-icons flex" onSubmit={handleSubmit}>
            <input
              className="foot_mail mt-4 px-2"
              placeholder="Email Address"
              value={email}
              onChange={onChange}
            />
            <img
              src={InputArrow}
              className="input_icons1 cursor-pointer"
              alt="email-icon"
              onClick={handleSubmit}
            />
          </form>
        </div>

        {/* <div className="foot_2"> */}
        <div className="foot2_container">
          <div className="footer_menus row-view">
            <div className="foot_left">
              <div className="foot_about cursor-pointer extraPadding">
                <Link className="foo_ab" to="/access">
                  ABOUT US
                  </Link>
              </div>
              <div className="foot_contact cursor-pointer extraPadding">
                <Link className="foo_ab" to="/faq">
                  CONTACT US
                  </Link>
              </div>
              <div className="foot_tc cursor-pointer extraPadding">
                <Link className="foo_ab" to="/tc">
                  TERMS & CONDITIONS
                  </Link>
              </div>
              <div className="foot_pp cursor-pointer extraPadding">
                <Link className="foo_ab" to="/privacy">
                  PRIVACY POLICY
                  </Link>
              </div>
            </div>
            <div className="foot_left">
              <div className="foot_sus cursor-pointer extraPadding">
                <Link className="foo_ab" to="/sustain">
                  SUSTAINABILITY
                  </Link>
              </div>
              {/* <div className="foot_fq cursor-pointer"></div> */}
              <div className="foot_conn extraPadding">CONNECT WITH US</div>
              <div className=" foot_logos">
                <a
                  href="https://www.linkedin.com/company/raymonduco/"
                  target="_blank"
                >
                  {" "}
                  <AiFillLinkedin
                    color="white"
                    className="cursor-pointer socialImage1"
                  />
                </a>
                <a
                  href="https://www.instagram.com/raymonduco/"
                  target="_blank"
                >
                  {" "}
                  <FaInstagram color="white" className="cursor-pointer" />
                </a>
              </div>
            </div>
          </div>
          <div className="footer_copy">
            @ RAYMOND UCO 2021. ALL RIGHTS RESERVED
            </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Footer;
