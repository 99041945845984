import * as actionTypes from "./shopping-types";

const INITIAL_STATE = {
  products: [],
  cart: [],
  currentItem: null,
};

const shopReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.ADD_TO_HANGER:
      const item = action.payload;
      const inCart = state.cart.find((item) =>
        item.id === action.payload.id ? true : false
      );
      return {
        ...state,
        cart: inCart
          ? state.cart.map((item) =>
              item.id === action.payload.id
                ? { ...item, hangerQTY: item.hangerQTY + 1 }
                : item
            )
          : [...state.cart, { ...item, hangerQTY: 1, yardageQTY: 0 }],
      };
    case actionTypes.SUBTRACT_TO_HANGER:
      return {
        ...state,
        cart: state.cart.map((item) =>
          item.id === action.payload.id
            ? {
                ...item,
                hangerQTY: item.hangerQTY !== 1 ? item.hangerQTY - 1 : 1,
              }
            : item
        ),
      };

    case actionTypes.ADD_TO_YARDAGE:
      const items = action.payload;
      const inThere = state.cart.find((item) =>
        item.id === action.payload.id ? true : false
      );
      return {
        ...state,
        cart: inThere
          ? state.cart.map((item) =>
              item.id === action.payload.id
                ? { ...item, yardageQTY: item.yardageQTY + 1 }
                : item
            )
          : [...state.cart, { ...items, yardageQTY: 1, hangerQTY: 0 }],
      };

    case actionTypes.SUBTRACT_TO_YARDAGE:
      return {
        ...state,
        cart: state.cart.map((item) =>
          item.id === action.payload.id
            ? {
                ...item,
                yardageQTY: item.yardageQTY !== 1 ? item.yardageQTY - 1 : 1,
              }
            : item
        ),
      };

    case actionTypes.REMOVE_FROM_CART:
      return {
        ...state,
        cart: state.cart.filter((item) => item.id !== action.payload.id),
      };

    case actionTypes.CLEAR_CART:
      return {
        ...state,
        cart: [],
      };

    default:
      return state;
  }
};

export default shopReducer;
