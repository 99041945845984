import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import "./allCollection.css";
import ProductComponent from "./ProductComponent";
import sanityClient from "../../client";
import { CircularProgress } from "@material-ui/core";

const AllCollection = () => {
  const [allPostsData, setAllPosts] = useState(null);
  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type=="allCollection"]{
            leftText,
            rightText,
            headingText,
          }`
      )
      .then((data) => setAllPosts(data[0]))
      .catch(console.error);
  }, []);

  if (!allPostsData)
    return (
      <div className="load_div">
        <CircularProgress />
      </div>
    );
  return (
    <div className="all_collection w-full h-full">
      {/* <div className="col_head"></div> */}
      <div className={'headingInAllCollection'}>{allPostsData.headingText}</div>
      <div className="grid grid-cols-2 w-9/12  m-auto mt-3 allHead">
        <div className="col_headLeft">
          <h5>{allPostsData.leftText}</h5>
        </div>
        <div className="col_headRight mt-3 ">
          <h6>{allPostsData.rightText}</h6>
        </div>
      </div>
      <ProductComponent />
      <div className="coll_footer">
        <Footer />
      </div>
    </div>
  );
};

export default AllCollection;
