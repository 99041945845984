import React, { useEffect, useState } from "react";
import "./sidenav.css";
import { FiMenu } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";
import { BiCartAlt } from "react-icons/bi";
import { RiShoppingBagLine, RiLogoutCircleRLine } from "react-icons/ri";
import UCO from "../../assets/UCOlogo.png";
import { useMediaQuery } from "react-responsive";
import { Badge, IconButton } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import AOS from "aos";
import "aos/dist/aos.css";
import { connect } from "react-redux";
import "sweetalert2/src/sweetalert2.scss";
import { Collapse } from "reactstrap";
import CartPopup from "../CartPage/CartPopup";
import sanityClient from "../../client";
import { CircularProgress } from "@material-ui/core";
import { DeviceSize } from "../DeviceSize";
import MobilePopUp from "../DeviceSize/MobilePopUp";
import "../../assets/styrene/style.css";
import "../../assets/styrene-bold/style.css";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useAuth } from "../../contexts/AuthContext";
import { auth, firebase, functions } from "../../firebaseConfig";
const db = firebase.firestore();

const SideNav = ({ carts }) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [showCart, setShowCart] = useState(false);
  AOS.init();
  const [cartCount, setCartCount] = useState(0);
  const [success, setSuccess] = useState(false);
  const [allPostsData, setAllPosts] = useState([]);
  const [sidebar, setSidebar] = useState(false);
  const [cart, setCart] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isOpenCollection, setIsOpenCollection] = useState(false);
  const [openMobile, setOpenMobile] = useState(false);
  const { logout } = useAuth();

  const [userName, setUserName] = useState("");

  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });

  let dis_color = document.querySelector(".l_home");
  let sus_color = document.querySelector(".l_home1");
  let ab_color = document.querySelector(".l_home2");
  let cn_color = document.querySelector(".l_home3");

  // const showCart = () => setCart(!cart);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleCollection = () => {
    setIsOpenCollection(!isOpenCollection);
    if (!isOpenCollection) {
      dis_color.classList.add("dis_color");
      sus_color.classList.add("dis_color");
      ab_color.classList.add("dis_color");
      cn_color.classList.add("dis_color");
    }
    if (isOpenCollection) {
      dis_color.classList.remove("dis_color");
      sus_color.classList.remove("dis_color");
      ab_color.classList.remove("dis_color");
      cn_color.classList.remove("dis_color");
    }
    if (!sidebar) {
      dis_color.classList.remove("dis_color");
      sus_color.classList.remove("dis_color");
      ab_color.classList.remove("dis_color");
      cn_color.classList.remove("dis_color");
    }
  };
  const showSidebar = () => {
    setSidebar(!sidebar);
  };

  const setWidth = {
    width: "60%",
  };

  if (isOpenCollection) {
    setWidth.width = "100%";
  }

  const onSuccess = (e) => {
    // Swal.fire({
    //   title: 'Your Order has been placed',
    //   text: 'Our Team will get back to you',
    // });
    setSuccess(true);
    setTimeout(() => {
      setIsEmpty(false);
      showCart();
      history.push("/checkout");
    }, 1500);
  };

  const openForm = () => {
    setShowModal((prev) => !prev);
  };

  let menuOpen = false;

  const menuBtn = document.querySelector(".menu-btn");
  const opMenu = () => {
    if (!menuOpen) {
      menuBtn.classList.add("open");
      menuOpen = true;
    } else {
      menuBtn.classList.remove("open");
      menuOpen = false;
    }
  };
  async function handleLogOut() {
    try {
      await logout();
      history.push("/");
    } catch {
      console.error("Error In Logout");
    }
  }

  useEffect(async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const data = firebase.auth().currentUser;
    let userData = await db.collection("users").where("email", "==", data.email).get()
      .then((data) => {
        let returnData = data.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))
        return returnData
      });
    if (userData.length > 0) {
      setUserName(userData[0].fname)
    }

    setCartCount(carts.length);
    sanityClient
      .fetch(
        `*[_type=="collectionPage"]{
           title,
           slug
        }`,
        {
          signal: signal,
        }
      )
      .then((data) => setAllPosts(data))
      .catch(console.error);

    return function cleanup() {
      abortController.abort();
    };
  }, [carts, cartCount]);

  if (!allPostsData)
    return (
      <div className="load_div">
        <CircularProgress />
      </div>
    );
  const renderProducts = allPostsData.map((product, slug1) => {
    let slug = product.slug.current;
    let visible = slug == "elite" || slug == "fluid" || slug == "the-green-line" || slug == "functional" || slug == "forever" ? true : false;
    if (visible) {
      return (
        <div className={'newHightClass'} key={slug1}>
          <Link
            className="l_home22"
            to={`/collection/${slug}`}
            onClick={() => {
              setIsOpenCollection(false);
              showSidebar();
            }}
          >
            {product.title}
          </Link>
        </div>
      );
    }
  });

  return (
    <>
      {/* <Modal showModal={showModal} setShowModal={setShowModal} /> */}
      <div
        className="mainHeader"
        role="navigation"
      >
        <div className="pl-7 cursor-pointer fix " style={{ flexBasis: '100%' }}>
          {/* <BiMenuAltLeft color="red" onClick={showSidebar} /> */}
          <FiMenu color="red" onClick={showSidebar} />
        </div>
        <div className="center_logo" style={{ flexBasis: '100%' }}>
          <Link to="/">
            <img src={UCO} className="raymond_logo" alt="uco_logo" />
          </Link>
        </div>
        <div className="rightSideHeader">

          <div className="profileIcon cursor-pointer">
            <CgProfile
              onClick={handleClick}
              color="red"
              aria-controls="simple-menu"
              aria-haspopup="true"
            />
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={'mainMenuClass'}
            >
              <MenuItem>
                <Link to="/myprofile" style={{ display: 'flex', alignItems: 'center' }} onClick={() => setAnchorEl(false)}>
                  <CgProfile
                    color="black"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    style={{ height: 20, width: 20 }}
                  />
                  <div className={'menuText'} style={{ color: 'black' }}>{userName}</div>
                </Link>
              </MenuItem>
              <MenuItem >
                <Link to="/myorder" style={{ display: 'flex', alignItems: 'center' }} onClick={() => setAnchorEl(false)}>
                  <RiShoppingBagLine
                    color="black"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    style={{ height: 20, width: 20 }}
                  />
                  <div className={'menuText'} style={{ color: 'black' }}>My Orders</div>
                </Link>
              </MenuItem>
              <MenuItem style={{ display: 'flex', alignItems: 'center' }} onClick={handleLogOut}>
                <RiLogoutCircleRLine
                  color="black"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  style={{ height: 20, width: 20 }}
                />
                <div className={'menuText'}>Logout</div>
              </MenuItem>
            </Menu>{" "}
          </div>

          <div>
            <IconButton
              aria-label="Show cart items"
              className="cursor-pointer"
              color="inherit"
            >
              <Badge badgeContent={cartCount} color="secondary">
                <BiCartAlt
                  color="red"
                  onClick={() => setShowCart((prev) => !prev)}
                />
              </Badge>
            </IconButton>
          </div>
        </div>
      </div>

      <nav
        className={sidebar ? "nav-menu active" : "nav-menu"}
        style={setWidth}
      >
        <div className="nav-menu-items">
          <div className="navbar-toggle">
            <div className="menu-bars">
              {/* <div className="close_icon1" onClick={showSidebar}>
                X
              </div> */}
              <AiOutlineClose
                className="cursor-pointer close_icon1"
                onClick={() => {
                  if (isOpenCollection) {
                    setIsOpenCollection(!isOpenCollection);
                  }
                  showSidebar()
                }}
              />

              <div className="navigations my-5 grid grid-cols-2">
                <div className="menu-margin nvg1">
                  <div className={"nvg_a"}>
                    <Link className={"l_home"} to="/" onClick={showSidebar}>
                      Home
                    </Link>
                  </div>
                  <div
                    className="nvg_a cursor-pointer"
                    onClick={toggleCollection}
                  >
                    <div className={"l_home"}>
                      Collection
                    </div>
                  </div>
                  <div className="nvg99">
                    <Collapse isOpen={isOpenCollection}>
                      <div className="nvg_b-111">{renderProducts}</div>
                    </Collapse>
                  </div>
                  <div className="nvg_c">
                    <Link
                      className="l_home1"
                      to="/sustain"
                      onClick={showSidebar}
                    >
                      Sustainability
                    </Link>
                  </div>
                  <div className="nvg_d">
                    <Link
                      className="l_home2"
                      to="/access"
                      onClick={showSidebar}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      About us
                    </Link>
                  </div>
                  <div className="nvg_e">
                    <Link className="l_home3" to="/faq" onClick={showSidebar} style={{ whiteSpace: 'nowrap' }}>
                      Contact us
                    </Link>
                  </div>
                </div>
                <div className="menu-margin nvg9" style={{ width: "60%" }}>
                  <Collapse isOpen={isOpenCollection}>
                    <div className="nvg_b-1">{renderProducts}</div>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {/* {isMobile && (
        <MobilePopUp openMobile={openMobile} setOpenMobile={setOpenMobile} />
      )} */}
      <CartPopup showCart={showCart} setShowCart={setShowCart} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    carts: state.shop.cart,
  };
};

export default connect(mapStateToProps)(SideNav);
